import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
// eslint-disable-next-line
import SweetAlert from 'sweetalert2'
import OtpInput from 'react-otp-input';

export default function RegisterVerifyMobile({ setLoginStep, setLoginType, signupVerifyScreen, setSignupVerifyScreen, signupVerifyPostData, setSignupVerifyPostData, setPopupContent, setShow, setPopupIcon, setPopupTitle }) {

  const { handleSubmit } = useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [emailOtpMessage, setEmailOtpMessage] = useState();

  const onSubmit = async(Formdata) => {

    setButtonSpinner(true);

    const Postdata = {
      email: signupVerifyPostData.emailAddress,
      mobile: signupVerifyPostData.mobileNumber,
      "mobileOtp": otp[0] + '' + otp[1] + '' + otp[2] + '' + otp[3] + '' + otp[4] + '' + otp[5],
      otp_send_on: 'mobile',
    };


    try {
      const response = await axioInstance.post(`otp-verify`, Postdata);

      // Check if the response status is not 200
      if (response.data.status !== 200) {
        SweetAlert.fire({
          toast: true,
          position: 'top-end',
          icon: response.data.status === 100 ? 'warning' : 'error',
          title: response.data.emailOtpMessage || 'An error occurred while registering. Please try again.',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer);
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer);
          },
        });

        setButtonSpinner(false);
        return;
      }

      // Handle successful response
      if (response.data.mobileVerify === 100) {
        setEmailOtpMessage('');
        window.location.href = "/sign-in";
      } else {
        setEmailOtpMessage(response.data.mobileOtpMessage);
        setButtonSpinner(false);
      }
    } catch (error) {
      // Handle errors like network issues, timeout, or no response
      let errorMessage = 'An error occurred. Please check your internet connection and try again.';

      if (error.response) {
        // Server responded with a status other than 2xx
        errorMessage = error.response.data.message || 'Server error. Please try again later.';
      } else if (error.request) {
        // No response received from the server
        errorMessage = 'No response from the server. Please try again after some time.';
      } else {
        // Other errors (e.g., request setup issue)
        errorMessage = error.message;
      }

      SweetAlert.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: errorMessage,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', SweetAlert.stopTimer);
          toast.addEventListener('mouseleave', SweetAlert.resumeTimer);
        },
      });

      setButtonSpinner(false);
    }

    /*setButtonSpinner(true);

    const Postdata = {
      "email": signupVerifyPostData.emailAddress,
      "mobile": signupVerifyPostData.mobileNumber,
      "mobileOtp": otp[0] + '' + otp[1] + '' + otp[2] + '' + otp[3] + '' + otp[4] + '' + otp[5],
      "otp_send_on": 'mobile'
    };
    axioInstance.post(`otp-verify`, Postdata).then(
      function (response, data) {

        if (response.data.status !== 200) {

          SweetAlert.fire({
            toast: true,
            position: 'top-end',
            icon: response.data.status === 100 ? 'warning' : 'error',
            title: response.data.emailOtpMessage || 'An error occurred while registering. Please try again.',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
            }
        })

          setButtonSpinner(false);
          return;
        }

        if (response.data.mobileVerify === 100) {
          setEmailOtpMessage('');
          window.location.href = "/sign-in";
        } else {
          setEmailOtpMessage(response.data.mobileOtpMessage);
          setButtonSpinner(false);
        }
      });*/

  }


  const skipNow = () => {
    window.location.href = "/sign-in";
  }

  // eslint-disable-next-line
  const [otpError, setOtpError] = useState();

  const [otp, setOtp] = useState('');

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const resendOTP = () => {

    setEmailOtpMessage('');

    const Otpdata = {
      "full_name": signupVerifyPostData.fullName,
      "email": signupVerifyPostData.emailAddress,
      "mobile": signupVerifyPostData.mobileNumber,
      "otp_send_on": 'mobile'
    };

    axioInstance.post(`send-otp-mobile`, Otpdata).then(
      function (response, data) {
        setOtp('');
        setMinutes(1);
        setSeconds(30);
      }
    )
  };

  return (
    <Fragment>
      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Verify Your Mobile Number</div>
          <div className="font13 fw500 color626">OTP has been sent to Mobile +91 - {signupVerifyPostData.mobileNumber.slice(0, 2) + signupVerifyPostData.mobileNumber.slice(2).replace(/.(?=...)/g, '*')}</div>
          {/*<button type="button" className="btn btn-outline-primary btn-sm" onClick={backbtn}><span className="blueleftarrow mr5" /> Back</button>*/}
        </div>

        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="font14 fw600 color212">Enter OTP to continue. <span className="colorfa8">*</span></div>
            <div className="mt12 otpfield">


              <OtpInput
                className={"otpfield"}
                name="otp"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} className="otp-field otp-field-npm" />}
              />

            </div>
            <div className="error mt5 font13 coloreb4">{emailOtpMessage ? emailOtpMessage : otpError}</div>



            <div className="otptext font13 color626 mt30">

              {seconds > 0 || minutes > 0 ? (
                <p>
                  Resend OTP in: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <>Did not receive the OTP? <span className="color0d6 pointer" onClick={resendOTP}>Resend again</span></>
              )}
            </div>

            <button type={buttonSpinner ? "button" : "submit"} disabled={buttonSpinner} className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Verify Mobile"}</button>


          </form>
          <div className="orline mt40 mb40">
            <span className="font13 color7b8 fw600">OR</span>
          </div>

          <div className="googlebtn font15 fw600 color222 pointer" onClick={skipNow}>
            Skip Now
          </div>

          <div className="logintnctext font12 fw500 color485 mt35">
            By continuing, you agree with <a href='https://www.finnovate.in/terms-conditions' target='_blank' rel="noreferrer" className="color06d">Terms of Use</a> of Finnovate Financial Services Pvt Ltd. Our <a href='https://www.finnovate.in/privacy-policy' target='_blank' rel="noreferrer" className="color06d">Privacy Policy</a> describes how data is handled at Finnovate.
          </div>

        </div>


      </div>
    </Fragment >
  )
}
