import React, { useState, useEffect } from 'react'
import { inWords } from '../global'
import SweetAlert from 'sweetalert2';

export default function HistoryCashflowAnalysis({ cashflowData, liabilityData, networthData }) {

  console.log(cashflowData);

  const [totalIncome, setTotalIncome] = useState(null);
  const [totalExpense, setTotalExpense] = useState(null);
  const [selfIncome, setSelfIncome] = useState(null);
  const [spouseIncome, setSpouseIncome] = useState(null);
  const [RentalIncome, setRentalIncome] = useState(null);
  const [InvestmentIncome, setInvestmentIncome] = useState(null);
  const [Bonus, setBonus] = useState(null);
  const [rentalExpense, setRentalExpense] = useState(null);
  const [Household, setHousehold] = useState(null);
  const [ChildrenEducation, setChildrenEducation] = useState(null);
  const [utilityBills, setUtilityBills] = useState(null);
  const [lifestyle, setLifestyle] = useState(null);
  const [vacation, setVacation] = useState(null);
  const [totalLoan, setTotalLoan] = useState(null);
  const [totalLiGi, setTotalLiGi] = useState(null);
  const [liabilities, setLiabilities] = useState([]);
  const [commitmentTotal, setCommitmentTotal] = useState(0);

  const [drratio, setDrratio] = useState('');
  const [emiburden, setEmiburden] = useState('');
  const [savingrate, setSavingrate] = useState('');


  const dbRatio = () => {

    SweetAlert.fire({
      title: "D/A RATIO",
      text: "Your loans should be less than 1/3 of your total assets.",
      timer: 5000,
    });

  }

  const emiRatio = () => {

    SweetAlert.fire({
      title: "EMI BURDEN",
      text: "Your EMIs should be less than 40 % of your Income.",
      timer: 5000,
    });

  }

  const savingRatio = () => {

    SweetAlert.fire({
      title: "SAVING RATE",
      text: "Your savings rate should be 25 % or more.",
      timer: 5000,
    });

  }

  useEffect(() => {

    setTotalIncome(cashflowData.income_total);
    setTotalExpense(cashflowData.expense_total);
    setLiabilities(liabilityData.rows);
    setTotalLoan(liabilityData.total_loans ? parseFloat(liabilityData.total_loans) : 0);
    setCommitmentTotal(parseInt(networthData.healthpremiumAmount ? networthData.healthpremiumAmount : 0) + parseInt(networthData.spousepremiumAmount ? networthData.spousepremiumAmount : 0) + parseInt(networthData.selfpremiumAmount ? networthData.selfpremiumAmount : 0));
    setTotalLiGi(networthData.lifeSum ? parseFloat(networthData.lifeSum) : 0);

    setDrratio(networthData.dr_ratio);
    setEmiburden(networthData.emi_burden);
    setSavingrate(networthData.saving_rate);

    cashflowData.rows.forEach(item => {

      switch (item.profIncome_sub_type) {
        case "IncomeSelf":
          setSelfIncome(item.profIncome_total_amount);
          break;
        case "IncomeSpouse":
          setSpouseIncome(item.profIncome_total_amount);
          break;
        case 'RentalIncome':
          setRentalIncome(item.profIncome_total_amount);
          break;
        case 'InvestmentIncome':
          setInvestmentIncome(item.profIncome_total_amount);
          break;
        case 'Bonus':
          setBonus(item.profIncome_total_amount);
          break;
        case 'Household':
          setHousehold(item.profIncome_total_amount);
          break;
        case 'rentalExpense':
          setRentalExpense(item.profIncome_total_amount);
          break;
        case 'ChildrenEducation':
          setChildrenEducation(item.profIncome_total_amount);
          break;
        case 'UtilityBills':
          setUtilityBills(item.profIncome_total_amount);
          break;
        case 'Lifestyle':

          setLifestyle(item.profIncome_total_amount);
          break;


        case 'Vacation':

          setVacation(item.profIncome_total_amount);
          break;


        default:
          break;
      }
    });

  }, [cashflowData, liabilityData, networthData]);

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Personal Information</span></div>

      <div className="font22 fw700 color0C4 pt30">Cashflow Analysis</div>

      <div className="font14 fw500 color182 mt20">
        The cash flow analysis is the projection of your Income, Expenses and Investments for the current year. A positive and consistent Cash flow is an essential to achieve your financial goals. The Savings that you make every year should be invested in such a way that you meet your financial goals.
      </div>

      <div className="inflowbox_outer mt30">
        <div className="inflowbox_outer_left">
          <div className="inflowbox_title font12 fw600 color6d7">TOTAL INFLOW</div>
          <div className="font12 fw500 color7B8 mt8">Your family’s total income including returns and inheritance.</div>
          <div className="font24 fw500 color263 mt12">{totalIncome > 0 ? inWords(totalIncome) : '00'}</div>
        </div>
        <div className="inflowbox_outer_right">
          <div className="inflowdtl">
            <div>
              <div className="font16 fw500 color263">{selfIncome > 0 ? inWords(selfIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Self income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{spouseIncome > 0 ? inWords(spouseIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Spouse income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{RentalIncome > 0 ? inWords(RentalIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Rental income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{InvestmentIncome > 0 ? inWords(InvestmentIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Investment Income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{Bonus > 0 ? inWords(Bonus) : '00'}</div>
              <div className="font13 color7B8 fw500">Variable/Bonus income</div>
            </div>
          </div>
        </div>
      </div>


      <div className="inflowbox_outer mt15">
        <div className="inflowbox_outer_left">
          <div className="inflowbox_title orangedot font12 fw600 color6d7">TOTAL OUTFLOW</div>
          <div className="font12 fw500 color7B8 mt8">Your family’s total expenses.</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalExpense)}</div>
        </div>
        <div className="inflowbox_outer_right">
          <div className="inflowdtl">
            <div>
              <div className="font16 fw500 color263">{inWords(Household)}</div>
              <div className="font13 color7B8 fw500">HouseHold</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(ChildrenEducation)}</div>
              <div className="font13 color7B8 fw500">Kids Education</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(rentalExpense)}</div>
              <div className="font13 color7B8 fw500">Rental Paid</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(vacation)}</div>
              <div className="font13 color7B8 fw500">Vacation</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(utilityBills)}</div>
              <div className="font13 color7B8 fw500">Utility Bill</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(lifestyle)}</div>
              <div className="font13 color7B8 fw500">Lifestyle</div>
            </div>
          </div>
        </div>
      </div>

      <div className="inflowbox_outer mt15">
        <div className="inflowbox_outer_left">
          <div className="inflowbox_title orangedot font12 fw600 color6d7">EMI & Commitments</div>
          <div className="font12 fw500 color7B8 mt8">Your family’s total EMIs and Commitments.</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalLoan + totalLiGi)}</div>
        </div>
        <div className="inflowbox_outer_right">
          <div className="inflowdtl">




            {
              liabilities.map((data, index) => {
                return (
                  <>

                    <div>
                      <div className="font16 fw500 color263">{inWords((data.profLiability_emi * 12))}</div>
                      <div className="font13 color7B8 fw500">{data.profLiability_instr_name}</div>
                    </div>
                  </>
                )
              })
            }

            <div>
              <div className="font16 fw500 color263">{(!networthData.healthpremiumAmount) ? '00' : inWords((networthData.healthpremiumAmount))}</div>
              <div className="font13 color7B8 fw500">{"Health Insurance"}</div>
            </div>

            <div>
              <div className="font16 fw500 color263">{!networthData.selfpremiumAmount ? '00' : inWords(parseFloat(networthData.selfpremiumAmount))}</div>
              <div className="font13 color7B8 fw500">{"Term Insurance"}</div>
            </div>

            <div>
              <div className="font16 fw500 color263">{!networthData.spousepremiumAmount ? '00' : inWords(parseFloat(networthData.spousepremiumAmount))}</div>
              <div className="font13 color7B8 fw500">{"Spouse Term Insurance"}</div>
            </div>


          </div>
        </div>
      </div>



      <div className="totalSaving_box mt15">
        <div>
          <div className="inflowbox_title font12 fw600 color6d7">TOTAL INFLOW</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalIncome)}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">-</div>
        </div>
        <div>
          <div className="inflowbox_title orangedot font12 fw600 color6d7">TOTAL OUTFLOW</div>
          <div className="font24 fw500 color263 mt12">{inWords(parseInt(totalExpense) + parseInt(totalLoan) + parseInt(commitmentTotal))}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">=</div>
        </div>
        <div>
          <div className="inflowbox_title bluedot font12 fw600 color6d7">TOTAL SAVING</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalIncome - (parseInt(totalExpense) + parseInt(totalLoan) + parseInt(commitmentTotal)))}</div>
        </div>
      </div>

      <div className="font22 fw700 color0C4 mt40">Financial Health</div>


      <div className="loanBox_outer mt25 p25">
        <div className="loandtl">
          <div className="font13 fw500 color6d7">D/A RATIO <i className="fa fa-info-circle" onClick={dbRatio}></i></div>
          <div className="font24 fw500 color182">{drratio > 0 ? drratio + ' %' : ' 0 %'}</div>
        </div>
        <div className="loandtl">
          <div className="font13 fw500 color6d7">EMI BURDEN <i className="fa fa-info-circle" onClick={emiRatio}></i></div>
          <div className="font24 fw500 color182">{emiburden > 0 ? emiburden + ' %' : ' 0 %'}</div>
        </div>
        <div className="loandtl">
          <div className="font13 fw500 color6d7">SAVING RATE <i className="fa fa-info-circle" onClick={savingRatio}></i></div>
          <div className="font24 fw500 color182">{savingrate > 0 ? savingrate + ' %' : ' 0 %'}</div>

        </div>
      </div>

      <div className="lineDevider mt40 mb40" />

    </>
  )
}
