import React, { useEffect, useState } from 'react'
import axioInstance from '../axiosInstance';
import formatAmount from 'indian-currency-formatter';

export default function RiskProfileSummary() {


  const [planHistory, setPlanHistory] = useState([]);
  const [planLatestStatus, setPlanLatestStatus] = useState();
  const [createdOn, setCreatedOn] = useState();

  const [riskHistory, setRiskHistory] = useState([]);

  const [profileId, setProfileId] = useState();

  // eslint-disable-next-line
  const [sharedHistory, setSharedHistory] = useState([]);

  const [historyToggle, setHistoryToggle] = useState(false);

  const getSharedHistory = () => {
    const profile_id = sessionStorage.getItem('profile_id');
    axioInstance.post(`profile/get-shared-lifecycle-history`, { profile_id: profile_id }).then((response, data) => {

      if (response.data.status === 100) {
        setSharedHistory(response.data.rows);
        setHistoryToggle(true);
      }else{
        setHistoryToggle(true);
      }


    });

  }

  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');

    axioInstance.post(`fpplanstatus/get`, { profile_id: profile_id }).then(
      (response, data) => {


        setPlanHistory(JSON.parse(response.data.history));
        setPlanLatestStatus(response.data.latest_status);
        setCreatedOn(response.data.CreatedOn);

        setProfileId(response.data.profile_id);
      });

    axioInstance.post(`get-risk-profile-logs`, { profile_id: profile_id }).then((response, data) => {

      setRiskHistory(response.data);

    });





  }, []);

  const regeneratePdf = (risk_id) => {

    axioInstance.post(`risk-profile-pdf-regenerate`, { risk_id: risk_id, profile_id: sessionStorage.getItem('profile_id') }).then((response, data) => {

      window.location.reload();

    });

  }

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Risk & Plan Summary</span></div>
      <div className="font22 fw700 color0C4 pt30">Plan Summary</div>


      <div className="goalbox_title mt15">
        <div>
          <div className="font16 color263 fw600">Latest Status: <span className="font13 fw500 color626">{planLatestStatus}</span></div>
        </div>
        <div>
          <div className="achivetab fw500 font12 color353">Created On: <span className="color007 ml5">{createdOn}</span></div>
        </div>
      </div>

      {
        planLatestStatus ?
          planHistory.map((history, key) => {
            return (
              <>

                <div className="loanBox_outer mt25 p25">
                  <div className="loandtl">
                    <div className="font13 fw500 color6d7">Status</div>
                    <div className="font14 fw500 color182">{history.status}</div>
                  </div>
                  <div className="loandtl">
                    <div className="font13 fw500 color6d7">Status Date</div>
                    <div className="font14 fw500 color182">{new Date(history.status_date).toLocaleDateString("en-GB")}</div>
                  </div>

                  {
                    sessionStorage.getItem("admin_id") === "1" && (
                      <>
                        <div className="loandtl">
                          <div className="font13 fw500 color6d7">PDF</div>
                          <div className="font14 fw500 color182">
                            {
                              history.ref_doc ? <><a href={"https://planapi.finnovate.in/uploads/riskpdf/" + history.ref_doc} target='_blank' rel="noreferrer" className='color111'><i className="fas fa-file-pdf fa-2x"></i></a></> : 'NA'
                            }
                          </div>
                        </div>
                      </>
                    )
                  }


                  <div className="loandtl">
                    <div className="font13 fw500 color6d7">Remark</div>
                    <div className="font14 fw500 color182">{history.remark}</div>
                  </div>



                  {
                    history.status === "Plan Freezed" && (
                      <>
                        <div className="loandtl">
                          <div className="font13 fw500 color6d7">Monthly</div>
                          <div className="font14 fw500 color182">{formatAmount(history.monthly_amount)}</div>
                        </div>
                        <div className="loandtl">
                          <div className="font13 fw500 color6d7">Yearly</div>
                          <div className="font14 fw500 color182">{formatAmount(history.yearly_amount)}</div>
                        </div>

                        <div className="loandtl">
                          <div className="font13 fw500 color6d7">Increase By</div>
                          <div className="font14 fw500 color182">{history.increase_by} %</div>
                        </div>

                        <div className="loandtl">
                          <div className="font13 fw500 color6d7">History</div>
                          <div className="font14 fw500 color182"><a href={'history-fp-report?id=' + profileId + '&doc_id=' + history.udid}>View</a></div>
                        </div>
                      </>
                    )
                  }



                </div>





              </>
            )
          }) : ''
      }



      <div className="goalbox_title mt50">
        <div>
          <div className="font16 color263 fw600">{"FP Report History"} <span className="font13 fw500 color626"></span></div>
        </div>
        <div>
          <div className="achivetab fw500 font12 color353">


            {
              historyToggle ? <span className="color007 ml5 pointer" onClick={() => { setHistoryToggle(false); setSharedHistory([]) }}>{"Hide History"}</span> : <span className="color007 ml5 pointer" onClick={getSharedHistory}>{"Get History"}</span>
            }


          </div>
        </div>
      </div>

      {
        sharedHistory ?
          sharedHistory.map((Sharedhistory, key) => {
            return (
              <>

                <div className="loanBox_outer mt25 p25" key={key}>

                  <div className="loandtl">
                    <div className="font13 fw500 color6d7">Status</div>
                    <div className="font14 fw500 color182">{Sharedhistory.status_type}</div>
                  </div>

                  <div className="loandtl">
                    <div className="font13 fw500 color6d7">Status Date</div>
                    <div className="font14 fw500 color182">{Sharedhistory.status_date ? new Date(Sharedhistory.status_date).toLocaleDateString("en-GB") : (Sharedhistory.created ? new Date(Sharedhistory.created).toLocaleDateString("en-GB") : 'NA')}</div>
                  </div>


                  <div className="loandtl">
                    <div className="font14 fw500 color182"><a href={'history-fp-report?id=' + Sharedhistory.profile_id + '&doc_id=' + Sharedhistory.doc_id}>View</a></div>
                  </div>
                </div>
              </>
            )
          }) : <span>No Data Found</span>
      }


      <div className="font22 fw700 color0C4 pt30">Risk Summary</div>



      {
        riskHistory.map((history, key) => {

          console.log(key);
          return (
            <>

              <div className="loanBox_outer mt25 p25">
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">Risk Description</div>
                  <div className="font14 fw500 color182">{history.risk_description}</div>
                </div>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">Date</div>
                  <div className="font14 fw500 color182">{new Date(history.created).toLocaleDateString("en-GB")}</div>
                </div>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">PDF</div>
                  <div className="font14 fw500 color182">{history.ref_doc ? <><a href={"https://planapi.finnovate.in/uploads/riskpdf/" + history.ref_doc} target='_blank' rel="noreferrer" className='color111'><i className="fas fa-file-pdf fa-2x"></i></a></> : ''}
                  </div>

                </div>
                {
                  key === 0 && (<><div className="loandtl">
                    <div className="inflowbox_title greendot font12 fw600 color6d7">Latest</div>
                  </div></>)
                }

                {
                  (sessionStorage.getItem('admin_id') === '1' && (history.ref_doc !== '' && history.ref_doc !== null)) && (<>
                    <div className="loandtl">
                      <div className="greendot font12 fw600 color6d7 pointer" onClick={() => regeneratePdf(history.risk_id)}>Regenrate</div>
                    </div>
                  </>


                  )
                }


              </div>

            </>
          )
        })
      }

    </>
  )
}
