import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react'
//import axioInstance from '../axiosInstance';
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
//import { Encryption } from '../global';
import { apiUrl } from '../components/global';


export default function ULoginSetPassword() {

    const [email_id, setEmail_id] = useState();
    const [token, setToken] = useState();
    useEffect(() => {

        const search = window.location.search;
        const params = new URLSearchParams(search);


        const email = params.get('email');
        const decodedEmail = decodeURIComponent(email); // Ensure decoding
        setEmail_id(decodedEmail);


        const token = params.get('token');
        setToken(token);
        // nothing yet

    }, []);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const [buttonSpinner, setButtonSpinner] = useState(false);


    const onSubmit = data => {

        setButtonSpinner(true);

        console.log(email_id);


        axios.post(apiUrl + 'ulogin/setpassword', {
            email_id: email_id,
            token: token,
            password: data.password,
            password_confirmation: data.cnfpassword
        }).then(function (response) {

            console.log(response.data);

            if(response.data.status === 101){
                SweetAlert.fire(
                    'Set Password',
                    'Something went wrong'+ response.data.msg,
                    'error'
                  );
            }else{

                SweetAlert.fire({
                    toast: true,
                    icon: 'success',
                    title: response.data.msg,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                      toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                  }).then(function(){
                    window.location.href = "/ulogin"
                  })
            }
        })



    }

    // Watch the password field
    const password = watch('password', '');
    return (
        <Fragment>
            <div className="innercontainer mb40">
                <div className="mainwrapper">



                    <div className="mt30 font20 fw600 color384 mob_pl20_pr20 d-none d-sm-block">Welcome to Finnovate,</div>
                    <div className="font13 color626 mob_pl20_pr20 d-none d-sm-block">Join us and set your path towards Financial Freedom</div>
                    <div className="row formWpr mt25">
                        <div className="col-lg-5 p0">


                            <div className="logincard">
                                <div className="logincard_title pt30 pb30 pl35 pr35">
                                    <div className="font18 color212 fw600">Set Password</div>
                                    <div className="font13 fw500 color626">Once Successfully password set redirected to ulogin</div>
                                </div>
                                <div className="p35">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="font14 fw600 color212">
                                            Password<span className="colorfa8 ml5">*</span>
                                        </div>
                                        <div className="mt12 mobilefield">
                                            <input
                                                type="text"
                                                id="password"
                                                name="password"
                                                autoComplete="off"
                                                {...register("password", {
                                                    required: 'Password is required',
                                                    minLength: {
                                                        value: 6,
                                                        message: 'Password must be at least 6 characters'
                                                    }
                                                })}
                                                placeholder="Enter Password"
                                            />
                                        </div>
                                        <span className="text-danger font11">
                                            {errors.password && errors.password.message}
                                        </span>
                                        <br />

                                        <div className="font14 fw600 color212">
                                            Confirm Password<span className="colorfa8 ml5">*</span>
                                        </div>
                                        <div className="mt12 mobilefield">
                                            <input
                                                type="text"
                                                id="cnfpassword"
                                                name="cnfpassword"
                                                autoComplete="off"
                                                {...register("cnfpassword", {
                                                    required: 'Confirm password is required',
                                                    validate: value =>
                                                        value === password || "Passwords do not match"
                                                })}
                                                placeholder="Enter Confirm Password"
                                            />
                                        </div>
                                        <span className="text-danger font11">
                                            {errors.cnfpassword && errors.cnfpassword.message}
                                        </span>

                                        <button type="submit" className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">
                                            {buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Set Password"}
                                        </button>
                                    </form>


                                </div>
                            </div>

                        </div>
                        <div className="col-lg-7 qouteouter d-none d-md-block">
                            {/* video container */}

                            <video
                                autoPlay
                                muted
                                loop
                                style={{ height: "100%", width: "100%", objectFit: "cover" }} //object-fit:cover
                            >
                                <source src={"https://planapi.finnovate.in/login-2.mp4"} type="video/mp4" />
                            </video>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
