import React from 'react'
import '../../css/header.css'
import '../../css/style.css'
import '../../css/dashboard.css'
import '../../css/plan.css'
import { useNavigate } from 'react-router-dom'
import axioInstance from '../axiosInstance'

export default function AdminDashboardHeader() {

    let navigate = useNavigate();

    const adminlogOut = () => {

        axioInstance.post(`login-logout-logs`, { user_type: 'adminclient', action: 'logout', admin_id: sessionStorage.getItem('admin_id') }).then(function (response, data) {

            sessionStorage.clear();
            localStorage.clear();
                // Clear "Remember Me" data
            localStorage.removeItem('remember_email');
            localStorage.removeItem('remember_password');
            localStorage.removeItem('remember_timestamp');

            navigate("/ulogin");

        });


    }


    return (
        <>
            <div className="headerWpr mainwrapper">
                <div className="innercontainer isPlanProfilesTimelinePage">
                    <div className="hdrCol pt12 pb12">
                        <div className="hdrleft font13 fw500 color182">
                            <span className="finnvoate_logo mr12"><img src='images/finnoNewLogo.jpg' alt='logo' /></span> <span className="">Welcome to Finnovate</span>

                        </div>


                        <div className="hdrright d-none d-md-block">




                            <span className="profileimg mr10"><img src='images/user.png' alt='User Profile' /></span>

                            <span className="hdr_downarrow">
                                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 3.68852L8.2821 0.303855C8.66657 -0.0926305 9.29966 -0.10237 9.69615 0.2821C10.0926 0.666571 10.1024 1.29966 9.7179 1.69615L5.7179 5.82115C5.32504 6.22629 4.67496 6.22629 4.2821 5.82115L0.2821 1.69615C-0.10237 1.29966 -0.0926305 0.666571 0.303855 0.2821C0.70034 -0.10237 1.33343 -0.0926305 1.7179 0.303855L5 3.68852Z" fill="#81878C" />
                                </svg>
                                <span className="profilelist">
                                    <ul className="font12 color111">
                                        <li onClick={adminlogOut}>Logout</li>
                                    </ul>
                                </span>
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}