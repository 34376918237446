import React, { Fragment } from 'react'
import axioInstance from './axiosInstance'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import { Encryption } from './global'

export default function AddDirectProfileSidebar({ setActive }) {

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const onSubmit = Formdata => {



        axioInstance.post(`profile/view`, { 'email': Encryption(Formdata.emailAddress), 'validate': '1' }).then(function (response, data) {

            if (response.data.status === 100) {
                setError('emailAddress', { type: 'custom', message: response.data.msg });
            } else {

                const search = window.location.search;
                const params = new URLSearchParams(search);
                const utm_source = params.get('utm_source');
                const utm_campaign = params.get('utm_campaign');
                const utm_id = params.get('utm_id');
                const admin_id = sessionStorage.getItem("admin_id");

                const Postdata = {
                    "full_name": Formdata.fullName,
                    "mobile": Formdata.mobileNumber,
                    "email": Formdata.emailAddress,
                    "password": Formdata.password,
                    "utm_source": utm_source,
                    "utm_campaign": utm_campaign,
                    "utm_id": utm_id,
                    "a_from": "adminDashboard",
                    "created_by": admin_id,
                    "ref_url": window.location.href
                };

                axioInstance.post(`add-guestuser`, Postdata).then(function () {
                    SweetAlert.fire({
                        title: 'Successfully added Guest User',
                        text: "Please verify Guest user with Admin to activate the profile.",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OKAY'
                    }).then((result) => {
                        window.location.href = "admin-dashboard";
                    });
                })

            }
        })


    }


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Add Guest User
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Add Guest User</div>
                        <div className="font14 fw500 color485">For Skipping OTP Purpose</div>
                    </div>

                    <div className="form_prnt p20">

                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label>Your full name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("fullName", { required: "Full name is required" })}
                                    placeholder="Enter full name"
                                    autoComplete="off"
                                />
                                {errors.fullName && <span className='redtext'>{errors.fullName.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    {...register("emailAddress", {
                                        required: "Email address is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                            message: "Enter a valid email address"
                                        }
                                    })}
                                    placeholder="Enter email address"
                                    autoComplete="nope"
                                />
                                {errors.emailAddress && <span className='redtext'>{errors.emailAddress.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Mobile number</label>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">+91</span>


                                    <input type="text" className="form-control" id="mobileNumber" name="mobileNumber" autoComplete='off' {...register("mobileNumber", { required: 'Mobile Number is required', pattern: { value: /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/, message: 'Invalid Mobile Number' } })} placeholder="Mobile Number" maxLength={10} />
                                </div>
                                {errors.mobileNumber && <span className='redtext'>{errors.mobileNumber.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="8-15 Charecters"
                                    autoComplete="off"
                                    {...register("password", { required: 'Password is required', minLength: { value: 8, message: 'Password is too short' }, maxLength: { value: 15, message: 'Password is too long' } })}
                                />

                                {errors.password && <span className='redtext'>{errors.password.message}</span>}
                            </div>

                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
