import React from 'react'
import axioInstance from '../components/axiosInstance';
import SweetAlert from 'sweetalert2'
import { useEffect } from 'react';
import { Encryption } from '../components/global';

export default function AutoLogin() {

   useEffect(() => {

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const email = params.get('email');
      const step = params.get("step");
      const admin_id = params.get('s');
      const Postdata = {
         "email": email
      };

 
      const adminId = localStorage.getItem('admin_id');
      if (adminId) {

         axioInstance.post(`admin/auto-login`, Postdata)
            .then(function (response) {

               if (response.data.status === 101) {
                  SweetAlert.fire({
                     toast: true,
                     icon: 'success',
                     title: 'No Plan Found/Created.',
                     animation: false,
                     position: 'top-right',
                     showConfirmButton: false,
                     timer: 4000,
                     timerProgressBar: true,
                     didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                     }
                  }).then(function () {

                     window.location.href = "/sign-in";

                  });
               } else {
                  SweetAlert.fire({
                     toast: true,
                     icon: 'success',
                     title: 'Signed in Successfully',
                     animation: false,
                     position: 'top-right',
                     showConfirmButton: false,
                     timer: 2000,
                     timerProgressBar: true,
                     didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                     }
                  }).then(function () {

                     sessionStorage.setItem('token', response.data.token);
                     const pdata = { "email": Encryption(response.data.dec_email) };

                     axioInstance.post(`profile/view`, pdata).then(function (response) {
                        sessionStorage.setItem('profile_id', Encryption(response.data.profile_id));
                        
                        //sessionStorage.setItem('full_name', response.data.first_name + '\'s');
                        
                        sessionStorage.setItem(
                           'full_name',
                           [response.data.first_name, response.data.middle_name, response.data.last_name] // Include all name fields
                             .filter(name => name) // Remove null or empty values
                             .join(" ") // Combine valid names with spaces
                             .toLowerCase() // Convert the entire string to lowercase
                             .split(" ") // Split into words
                             .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                             .join(" ") // Join words back with spaces
                         );
                         

                        sessionStorage.setItem('profile_details_id', Encryption(response.data.profile_details_id));
                        sessionStorage.setItem('admin_id', admin_id);
                        axioInstance.post(`login-logout-logs`, { user_type: 'adminclient', action: 'login', profile_id: Encryption(response.data.profile_id), admin_id: admin_id }).then(function (response, data) {

                           if (step <= 5) {
                              window.location.href = "https://plan.finnovate.in/basic-profile";
                           } else {
                              window.location.href = "https://plan.finnovate.in/dashboard";
                           }

                        });

                     });

                     //

                  });
               }

            })
            .catch(e => {
               SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
            });

      } else {
         SweetAlert.fire("Warning", "Session Expired. Login and try", "warning").then(function () {
            window.location.href = "ulogin";
         });
      }


   });

   return (<> <div>auto login</div></>)
}
