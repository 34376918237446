import React, { useEffect, useState } from 'react'
import { inWords, inFrequency } from '../global';
import formatAmount from 'indian-currency-formatter';

export default function HistoryLifeInsuranceAnalysis({ lifeGoalsData, assumptionData, riskInvData }) {

    console.log(lifeGoalsData);

    const [life, setLife] = useState([]);
    const [riskGoals, setRiskGoals] = useState([]);
    // eslint-disable-next-line
    const [assumption, setAssumption] = useState([]);

    useEffect(() => {

        setRiskGoals(lifeGoalsData);
        setAssumption(assumptionData);
        setLife(riskInvData);

    }, [lifeGoalsData, assumptionData, riskInvData]);

    return (
        <>

            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Life Insurance Analysis</span></div>

            <div className="font22 fw700 color0C4 pt30">Life Insurance Analysis</div>

            <div className="font14 fw500 color182 mt20">Your life insurance is a financial cover that you have in case on any eventuality related to your life. It your family with a financial security for their living expenses, goals, any liabilities etc.</div>


            {
                riskGoals.map((item, index) => {


                    return (
                        <>
                            {
                                item.profGoals_goal_type === "Life Insurance" && (
                                    <>
                                        <div className="font18 fw500 color mt25">Life Insurance Plan For : <b>{item.profGoals_fullname}</b></div>
                                        <div className="objectivenote mt25">
                                            <div className="font14 fw600 color1e1">Objective :</div>
                                            <div className="font14 fw500 color384">To cover an Yearly Income of Rs {formatAmount(item.income)} OR Yearly Expenses of Rs. {formatAmount(item.expense)} for next {Math.round(item.profGoals_grow_till)} years</div>
                                        </div>

                                        <div className="analysisnote mt8">
                                            <div className="font14 fw600 color1e1">Suggestions :</div>
                                            <ul className="analysislist font14 fw500">
                                                <li>The additional Life Insurance Cover required is in the range of Rs {item.suggestedoption2 > 0 ? formatAmount(item.suggestedoption2) : 0} to Rs. {item.suggestedoption1 > 0 ? formatAmount(item.suggestedoption1) : 0}</li>
                                            </ul>
                                        </div>

                                        <div className="lifecoveredbox_outer mt25">
                                            <div className="lifecoveredbox">
                                                <div className="font14 fw500 color263">Your Existing Life Cover Is</div>
                                                <div className="flex_center justify_center mt20">
                                                    <div>
                                                        <div className="font13 fw500 color626">Current</div>
                                                        <div className="fw500 font16 color182">{item.existing_cover > 0 ? inWords(item.existing_cover) : 0}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="lifecoveredbox">
                                                <div className="font14 fw500 color263">Life Covered Required</div>
                                                <div className="flex_center justify_center mt20">
                                                    <div>
                                                        <div className="font13 fw500 color626">Maximum</div>
                                                        <div className="fw500 font16 color007">{item.suggestedoption1 > 0 ? inWords(item.suggestedoption1) : 0}</div>
                                                    </div>
                                                    <div>
                                                        <div className="font13 fw500 color626">Minimum</div>
                                                        <div className="fw500 font16 color007">{item.suggestedoption2 > 0 ? inWords(item.suggestedoption2) : 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lifecoveredbox">
                                                <div className="font14 fw500 color263">Additional Cover Required</div>
                                                <div className="flex_center justify_center mt20">
                                                    <div>
                                                        <div className="font13 fw500 color626">Maximum</div>
                                                        <div className="fw500 font16 coloreb4">{inWords(item.suggestedoption1 - item.existing_cover)}</div>
                                                    </div>
                                                    <div>
                                                        <div className="font13 fw500 color626">Minimum</div>
                                                        <div className="fw500 font16 coloreb4">{(item.suggestedoption2 - item.existing_cover) > 0 ? inWords(item.suggestedoption1 - item.existing_cover) : '00'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="analysisnote mt25" style={{ 'backgroundColor': 'rgba(252, 157, 79, 0.2)' }}>
                                            <div className="font13 fw600 color1e1">Assumptions :</div>
                                            <ul className="analysislist font14 fw500">
                                                <li>Assumed rate of Growth in your income over the years is {Math.round(item.profGoals_income_grow * 100)} % and for your expenses is {Math.round(item.profGoals_goal_inflation * 100)} %</li>
                                                <li>Rate Of Return on the Fund Received = <b>{Math.round(item.profGoals_post_return * 100)} %</b></li>
                                            </ul>
                                        </div>

                                        <div className="lineDevider mt40 mb40" />
                                    </>
                                )

                            }
                        </>
                    );
                })
            }


            {
                life.length > 0 &&
                (
                    <>
                        <div className="font18 fw600 color182">Details of your Existing Life Insurance</div>

                        {
                            life.map((data, index) => {
                                return (
                                    <>
                                        {
                                            (data.profInvestments_type === 4) && (
                                                <>

                                                    <div className="loanBox_outer mt20 p25">
                                                        <div className="loandtl">
                                                            <div className="font16 fw600 color263">{data.first_name}</div>
                                                            <div className="font11 fw500 color7b8">Policy Holder</div>
                                                        </div>
                                                        <div className="loandtl">
                                                            <div className="font12 fw500 color6d7">Sum Insured</div>
                                                            <div className="font16 fw500 color162">{inWords(data.profInvestments_current_value)}</div>
                                                        </div>
                                                        <div className="loandtl">
                                                            <div className="font12 fw500 color6d7">Premium Amount</div>
                                                            <div className="font16 fw500 color162">{inWords(data.profInvestments_invest_amount)}</div>
                                                        </div>
                                                        <div className="loandtl">
                                                            <div className="font12 fw500 color6d7">Premium Frequency</div>
                                                            <div className="font16 fw500 color162">{inFrequency(data.profInvestments_frequency)}</div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            })
                        }
                    </>
                )
            }


        </>
    )
}
