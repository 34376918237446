import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../components/axiosInstance';
import SweetAlert from 'sweetalert2'
import axios from 'axios';
import { Encryption } from '../components/global';

export default function VerifyOtp({ verifyPostData, setVerifyScreen }) {

    console.log(verifyPostData);
    const { register, handleSubmit, setError, formState: { errors, isDirty, isValid } } = useForm();

    const [otpCount, setOtpCount] = useState(0);
    const resendOtp = () => {

        axioInstance.post(`send-otp`, verifyPostData).then(
            function (response, data) {
                setOtpCount(otpCount + 1)
            }
        );

    }

    const onSubmit = data => {

        const Postdata = {
            "mobile": verifyPostData.mobileNumber,
            "email": verifyPostData.emailAddress,
            "emailOtp": data.emailOtp,
            "mobileOtp": data.mobileOtp
        };

        axioInstance.post(`otp-verify`, Postdata).then(
            function (response, data) {


                console.log(response.data);

                if (response.data.emailVerify === 101) {
                    setError('emailOtp', { type: 'custom', message: 'Email OTP is Invalid' });
                }

                if (response.data.mobileVerify === 101) {
                    setError('mobileOtp', { type: 'custom', message: 'Mobile OTP is Invalid' });
                }


                if (response.data.emailVerify === 100 && response.data.mobileVerify === 100) {



                    const Postdata = {
                        "name": verifyPostData.fullName,
                        "mobile": verifyPostData.mobileNumber,
                        "email": verifyPostData.emailAddress,
                        "password": verifyPostData.password,
                        "register": 1,
                        "registerVia": 2,
                        "rel_manager": ''
                    };

                    axioInstance.post(`register`, Postdata)
                        .then(function (response) {

                            axioInstance.post(`profile/add`, Postdata).then(function (response) {
                                sessionStorage.setItem('profile_id', Encryption(response.data.profile_id));

                                axioInstance.post(`default-risk-profile`, { profile_id: Encryption(response.data.profile_id) }).then(function (response) {
                                });

                                axioInstance.post(`profile/summary-add`, { profile_id: Encryption(response.data.profile_id) }).then(function (response) {
                                });


                                let fullname = verifyPostData.fullName;
                                var names = fullname.split(' ');

                                const apiData = JSON.stringify({
                                    "campaign_id": '9423',
                                    "campaign_type": 'TeleSales',
                                    "check_condication_1": '0',
                                    "check_condication_2": '0',
                                    "ignore_duplicate": '1',
                                    "ignore_open_task": '1',
                                    "check_condication_task": '0',
                                    "consider_same_account": '0',
                                    "match_col": 'email_and_mobile',
                                    "lead_source": 'FinnPlan',
                                    "lead_caimpaign": 'FinnPlan',
                                    "relationship_manager": 'FINNOVATE',
                                    "tele_caller": '1000049',
                                    "import_data": {
                                        0: {
                                            "f_name": names[0] ? names[0] : '',
                                            "m_name": '',
                                            "l_name": names[1] ? names[1] : '',
                                            "name": verifyPostData.fullName,
                                            "mobile": verifyPostData.mobileNumber,
                                            "email": verifyPostData.emailAddress
                                        }
                                    }
                                });

                                axios.post(`https://lmslive.finnovate.in/import/import-everything-api.php`, apiData).then(function (response) {
                                });


                                /*axioInstance.post(`sendinblue/add-list`, { listid: 19, action: 'insert', email: verifyPostData.emailAddress, attributes: { EMAIL: verifyPostData.emailAddress, FULL_NAME: verifyPostData.mobileNumber, MOBILE: '+91' + verifyPostData.mobileNumber, SMS: '+91' + verifyPostData.mobileNumber, LEAD_SOURCE: 4 } }).then(function (response) {
                                });*/

                                axioInstance.post(`get-plan-steps`, { profile_id: response.data.profile_id }).then(
                                    (response, data) => {
                                        if (response.data.status === 100) {
                                            SweetAlert.fire(
                                                'Sign Up',
                                                'Successfully Registered On Finnovate.',
                                                'success'
                                            ).then(function () {
                                                window.location.href = "/sign-in";
                                            });
                                        }
                                    }
                                );



                            });

                        })
                        .catch(e => {

                            if (e.response.data.data.hasOwnProperty("password")) {
                                SweetAlert.fire({
                                    toast: true,
                                    icon: 'error',
                                    title: e.response.data.data.password[0],
                                    animation: false,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                                    }
                                });
                            } else {
                                SweetAlert.fire("Warning", "" + e.response.data.data.email[0] + "", "warning");
                            }

                        });

                }



            }
        );

    }

    const [timer, setTimer] = useState(45);
    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

    useEffect(() => {
        timer > 0 && setTimeout(timeOutCallback, 1000);
    }, [timer, timeOutCallback]);

    console.log(timer);

    const resetTimer = function () {
        if (!timer) {
            setTimer(45);
        }
    };

    return (
        <Fragment>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formtitle flex_center justify_center">
                    <div>
                        <div className="font20 fw600">Login</div>
                        <div className="font15 color495 ">Basic details</div>
                    </div>
                    <div>

                        <a href='/register' className="bluebtn font13 fw600">Back to Register
                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                            </svg></span></a>
                    </div>
                </div>

                {/* Form */}



                <div className="form_prnt pt25 pl35 pr35 pb35">

                    <div className="objectivenote mt25">
                        <div className="font14 fw500 color384">
                            <span className="font14 fw600 color1e1">Email :</span> {verifyPostData.emailAddress} <span className="font14 fw600 color1e1">And Mobile : </span> {verifyPostData.mobileNumber} <span className="font14 fw600 color1e1 goBackResetBtn" onClick={() => setVerifyScreen(false)}>Incorrect. go back &amp; change</span>
                        </div>
                    </div>

                    <div className="custome_form">

                        <div className="form-row">

                            <div className="form-group col-md-6 pr20">
                                <label htmlFor="emailAddress" className="font14 fw600">Mobile OTP</label>
                                <input type="text" className="form-control" id="emailAddress" name="mobileOtp" autoComplete='off' {...register("mobileOtp", { required: 'Mobile OTP is required', pattern: { value: /^[0-9][0-9][0-9][0-9][0-9][0-9]$/, message: 'Invalid OTP' } })} placeholder="Mobile OTP" maxLength={6} />
                                <span className="text-danger"> {errors.mobileOtp && errors.mobileOtp.message}</span>
                            </div>


                            <div className="form-group col-md-6 pl20">

                            </div>

                            <div className="form-group col-md-6 pr20">
                                <label htmlFor="emailAddress" className="font14 fw600">Email OTP</label>
                                <input type="text" className="form-control" id="emailOtp" name="emailOtp" autoComplete='off' {...register("emailOtp", { required: 'Email OTP is required', pattern: { value: /^[0-9][0-9][0-9][0-9][0-9][0-9]$/, message: 'Invalid OTP' } })} placeholder="Email OTP" maxLength={6} />
                                <span className="text-danger"> {errors.emailOtp && errors.emailOtp.message}</span>
                            </div>


                            <div className="form-group col-md-6 pl20">

                            </div>

                            <div className="form-group col-md-6 pr20">
                                {
                                    timer <= 0 && otpCount <= 2 ? <button type='button' className='bluebtn font13 fw600' onClick={() => { setTimer(45); resendOtp(); }}>Resend  <i className="fas fa-sync ml-1"></i></button> : (otpCount >= 2 ? '' : <div onPress={resetTimer}>Resend OTP ({timer})</div>)
                                }
                            </div>


                            <div className="form-group col-md-6 pl20">

                            </div>




                            <button className="bluebtn font13 fw600" type='submit' disabled={!isDirty && !isValid}>Verify OTP
                                <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                </svg></span></button>

                        </div>
                    </div>
                </div>
            </form>

        </Fragment>
    )
}
