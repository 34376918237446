import React, { Fragment, useState, useEffect } from 'react'
import axioInstance from '../axiosInstance';
import {  inWords } from '../global';
//import formatAmount from 'indian-currency-formatter';

import TermSuggestionSidebar from './EditInvestment/TermSuggestionSidebar';
import HealthSuggestionSidebar from './EditInvestment/HealthSuggestionSidebar';
import { Encryption } from '../global';

export default function DashboardRightInsuranceGrid({setInsuranceGraph, setInsuranceGraphData, setInsuranceGraphType}) {

    // eslint-disable-next-line
    const [riskDetails, setRiskDetails] = useState([]);
    // eslint-disable-next-line
    const [lifeGoal, setLifeGoal] = useState([]);
    // eslint-disable-next-line
    const [spouselifeGoal, setSpouselifeGoal] = useState([]);
    // eslint-disable-next-line
    const [healthGoal, setHealthGoal] = useState([]);

    // eslint-disable-next-line
    const [self, setSelf] = useState([]);
    // eslint-disable-next-line
    const [spouse, setSpouse] = useState([]);

    const [isActive, setActive] = useState(false);
    const [insuranceDetails, setInsuranceDetails] = useState([]);
    const toggleClass = (form, data) => {
        setActive(form);
        setInsuranceDetails(data);
    };

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');

        axioInstance.post(`goals/get-risk-goals`, { profile_id: profile_id }).then(
            (response, data) => {
                setRiskDetails(response.data);


                let life_goal = response.data.riskgoals.find(product => (product.goal_type === "Life Insurance" && product.relation === "self"));
                let health_goal = response.data.riskgoals.find(product => product.goal_type === "Health Insurance");
                let life_goal_spouse = response.data.riskgoals.find(product => Encryption(product.plan_for) === sessionStorage.getItem('spouse_prof_det_id'));

                console.log('life_goal_spouse', life_goal_spouse);

                console.log('spouse profile id', sessionStorage.getItem('spouse_prof_det_id'));

                setLifeGoal(life_goal ? life_goal : false);
                setSpouselifeGoal(life_goal_spouse ? life_goal_spouse : false);
                setHealthGoal(health_goal ? health_goal : false);

            });

        axioInstance.post(`profile-details/get-details`, {profile_id:profile_id}).then(
            (response, data) => {
                let self = response.data.find(product => product.relation === "self");
                setSelf(self);

                let spouse = response.data.find(product => product.relation === "spouse");
                setSpouse(spouse ? spouse : false);
            });

    }, []);

    const openGraphChart = (openGraphChart, lifeType) =>{

        console.log('clicked');
        setInsuranceGraph('show');
        setInsuranceGraphData(openGraphChart);
        setInsuranceGraphType(lifeType);
    }

    return (
        <Fragment>
            <div className="familycard_container">
                <div className="familycardtabs">
                    <div className="db_cashflow_outer pt20 pl30 pr30">
                        <ul className="nav nav-pills mb-3 font12 fw500 color353" id="pills-tab" role="tablist">

                            <li className="nav-item">
                                <a className="nav-link active" id="pills-person-tab" data-toggle="pill" href="#pills-person" role="tab" aria-controls="pills-person" aria-selected="false">{"Term Insurance"}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="pills-spouse-tab" data-toggle="pill" href="#pills-spouse" role="tab" aria-controls="pills-spouse" aria-selected="false">{"Health Insurance"}</a>
                            </li>


                        </ul>
                        <div className="tab-content" id="pills-tabContent">

                            <div className="tab-pane fade show active" id="pills-person" role="tabpanel" aria-labelledby="pills-person-tab">


                                <div className="coveragetitle">
                                    <div className="coveragetitle_icon coveragetitle_icon_blue">
                                        <span className="coverageicon1" />
                                    </div>
                                    <div className="pl15">
                                        <div className="font14 fw500 color6d7">{"Self"} Term Cover</div>
                                        <div className="font21 fw700 color182">{lifeGoal.select_opt === "income_grow" ? inWords(lifeGoal.suggestedoption2) : inWords(lifeGoal.suggestedoption1)}</div>
                                    </div>
                                </div>
                                <div className="coveragechart_card mt20">
                                    <div className="coveragechart_card_hdr font12 fw500 color949 pb10">
                                        <div className="coveragechart_card_hdr_inner">Protection</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Income)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Goals)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Expense)</div>
                                        <div className="coveragechart_card_hdr_inner">Recommendation</div>
                                    </div>
                                    <div className="coveragechart_card_row font14 fw600 color263">
                                        <div className="coveragechart_card_row_inner colorfb9">{lifeGoal.bar_perc > 100 ? 100 : lifeGoal.bar_perc}%</div>
                                        <div className="coveragechart_card_row_inner">{inWords(lifeGoal.suggestedoption2)} {lifeGoal.select_opt === "income_grow" ? <span className="greentick ml5" /> : ''} {lifeGoal.suggestedoption2 > 0 ? <i className="fa fa-info-circle ml-1" aria-hidden="true" title='See How' onClick={()=>openGraphChart(lifeGoal, 'income')}></i> : ''}</div>
                                        <div className="coveragechart_card_row_inner">-</div>
                                        <div className="coveragechart_card_row_inner">{inWords(lifeGoal.suggestedoption1)} {lifeGoal.select_opt === "regular" ? <span className="greentick ml5" /> : ''} {lifeGoal.suggestedoption1 > 0 ? <i className="fa fa-info-circle ml-1" aria-hidden="true" title='See How' onClick={()=>openGraphChart(lifeGoal, 'expense')}></i> : ''}</div>
                                        <div className="coveragechart_card_row_inner">
                                            <span className="color0d6 font13 pointer" onClick={() => toggleClass('term', lifeGoal)}>View Suggestion <span className="bluerightarrow ml5"></span></span>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="coveragetitle mt35">
                                    <div className="coveragetitle_icon coveragetitle_icon_blue">
                                        <span className="coverageicon1" />
                                    </div>
                                    <div className="pl15">
                                        <div className="font14 fw500 color6d7">{"Spouse"} Term Cover</div>
                                        <div className="font21 fw700 color182">{spouselifeGoal.select_opt === "income_grow" ? inWords(spouselifeGoal.suggestedoption2) : inWords(spouselifeGoal.suggestedoption1)}</div>
                                    </div>
                                </div>
                                <div className="coveragechart_card mt20">
                                    <div className="coveragechart_card_hdr font12 fw500 color949 pb10">
                                        <div className="coveragechart_card_hdr_inner">Protection</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Income)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Goals)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Expense)</div>
                                        <div className="coveragechart_card_hdr_inner">Recommendation</div>
                                    </div>
                                    <div className="coveragechart_card_row font14 fw600 color263">
                                        <div className="coveragechart_card_row_inner colorfb9">{spouselifeGoal.bar_perc > 100 ? 100 : spouselifeGoal.bar_perc}%</div>
                                        <div className="coveragechart_card_row_inner">{inWords(spouselifeGoal.suggestedoption2)} {spouselifeGoal.select_opt === "income_grow" ? <span className="greentick ml5" /> : ''} {spouselifeGoal.suggestedoption2 > 0 ? <i className="fa fa-info-circle ml-1" aria-hidden="true" title='See How' onClick={()=>openGraphChart(spouselifeGoal, 'income')}></i> : ''}</div>
                                        <div className="coveragechart_card_row_inner">-</div>
                                        <div className="coveragechart_card_row_inner">{inWords(spouselifeGoal.suggestedoption1)} {spouselifeGoal.select_opt === "regular" ? <span className="greentick ml5" /> : ''} {spouselifeGoal.suggestedoption1 > 0 ? <i className="fa fa-info-circle ml-1" aria-hidden="true" title='See How' onClick={()=>openGraphChart(spouselifeGoal, 'expense')}></i> : ''}</div>
                                        <div className="coveragechart_card_row_inner">
                                            <span className="color0d6 font13 pointer" onClick={() => toggleClass('term', spouselifeGoal)}>View Suggestion <span className="bluerightarrow ml5"></span></span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="tab-pane fade" id="pills-spouse" role="tabpanel" aria-labelledby="pills-spouse-tab">

                                <div className="coveragetitle">
                                    <div className="coveragetitle_icon">
                                        <span className="coverageicon2" />
                                    </div>
                                    <div className="pl15">
                                        <div className="font14 fw500 color6d7">Health Coverage</div>
                                        <div className="font21 fw700 color182">{inWords(healthGoal.max_suggested)}</div>
                                    </div>
                                </div>
                                <div className="coveragechart_card mt20">
                                    <div className="coveragechart_card_hdr font12 fw500 color949 pb10">
                                        <div className="coveragechart_card_hdr_inner">Protection</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Income)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Goals)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Expense)</div>
                                        <div className="coveragechart_card_hdr_inner">Recommendation</div>
                                    </div>
                                    <div className="coveragechart_card_row font14 fw600 color263">
                                        <div className="coveragechart_card_row_inner colorfb9">{healthGoal.bar_perc > 100 ? 100 : healthGoal.bar_perc}%</div>
                                        <div className="coveragechart_card_row_inner">{inWords(healthGoal.max_suggested)} <span className="greentick ml5" /></div>
                                        <div className="coveragechart_card_row_inner">-</div>
                                        <div className="coveragechart_card_row_inner">{inWords(healthGoal.min_suggested)}</div>
                                        <div className="coveragechart_card_row_inner">
                                            <span className="color0d6 font13 pointer" onClick={() => toggleClass('health', healthGoal)}>View Suggestion <span className="bluerightarrow ml5"></span></span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="tab-pane fade" id="pills-kids" role="tabpanel" aria-labelledby="pills-kids-tab">
                                kids
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                isActive === "term" && (<TermSuggestionSidebar toggleClass={toggleClass} setActive={setActive} insuranceDetails={insuranceDetails} />)
            }
            {
                isActive === "health" && (<HealthSuggestionSidebar toggleClass={toggleClass} setActive={setActive} insuranceDetails={insuranceDetails} />)
            }

        </Fragment>
    )
}
