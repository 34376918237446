import React, { Fragment, useEffect, useState } from 'react'
import '../css/login.css';
import Ulogin from '../components/login/Ulogin';
import ULoginPasswordReset from '../components/login/ULoginPasswordReset';

export default function ULogin() {


    useEffect(() => {

        document.body.classList.add('white_bg_fp_report');

        return function cleanup() {
            document.body.classList.remove('white_bg_fp_report');
        };


    }, []);


    const [uforgotPass, setUforgotPass] = useState(false);

    return (
        <Fragment>

            <div className="innercontainer mb40">
                <div className="mainwrapper">



                    <div className="mt30 font20 fw600 color384 mob_pl20_pr20 d-none d-sm-block">Welcome to Finnovate,</div>
                    <div className="font13 color626 mob_pl20_pr20 d-none d-sm-block">Join us and set your path towards Financial Freedom</div>
                    <div className="row formWpr mt25">
                        <div className="col-lg-5 p0">

                            {
                                uforgotPass ? <ULoginPasswordReset setUforgotPass={setUforgotPass} /> : <Ulogin setUforgotPass={setUforgotPass} />
                            }

                        </div>
                        <div className="col-lg-7 qouteouter d-none d-md-block">
                            {/* video container */}

                            <video
                                autoPlay
                                muted
                                loop
                                style={{ height: "100%", width: "100%", objectFit: "cover" }} //object-fit:cover
                            >
                                <source src={"https://planapi.finnovate.in/login-2.mp4"} type="video/mp4" />
                            </video>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
