import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2';
import axioInstance from '../../axiosInstance';
import {  ageCalculate } from '../../global';
import formatAmount from 'indian-currency-formatter';
import { Decryption } from '../../global';

export default function EditExpense({ toggleClass, setActive }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);
  
  const Back = () => {
    setActive(false);
  }

  const onSubmit = data => {

    setButtonSpinner(true);

    const Postdata = {
      profile_id: sessionStorage.getItem('profile_id'),
      type: 2,
      consider: 1,
      step: "2",
      income: {
        ChildrenEducation: {
          "sub_type": "ChildrenEducation",
          "amount": data.ChildrenEducation.replace(/,/g, ""),
          "frequency": data.ChildrenEducationFreq
        },
        Household: {
          "sub_type": "Household",
          "amount": data.Household.replace(/,/g, ""),
          "frequency": data.HouseholdFreq
        },
        RentalExpense: {
          "sub_type": "rentalExpense",
          "amount": data.rentalExpense.replace(/,/g, ""),
          "frequency": data.rentalExpenseFreq
        },
        UtilityBills: {
          "sub_type": "UtilityBills",
          "amount": data.UtilityBills.replace(/,/g, ""),
          "frequency": data.UtilityBillsFreq
        },
        Lifestyle: {
          "sub_type": "Lifestyle",
          "amount": data.Lifestyle.replace(/,/g, ""),
          "frequency": data.LifestyleFreq
        },
        Vacation: {
          "sub_type": "Vacation",
          "amount": data.Vacation.replace(/,/g, ""),
          "frequency": data.VacationFreq
        }
      }
    };

    axioInstance.post(`profile-income`, Postdata)
      .then(function (response) {

        const selfLiParams = {
          "profile_id": sessionStorage.getItem('profile_id'),
          "profile_details_id": sessionStorage.getItem('profile_details_id'),
          "admin_id":sessionStorage.getItem("admin_id"),
          "dob": ageCalculate(selfDetails.dob),
          "inflation": assumptions.inflation,
          "income_grow": assumptions.income_grow,
          "api_type": "insert",
          "goal_group": 2,
          "post_retire_inflation": assumptions.post_retire_inflation,
          "life_expentancy": assumptions.life_expentancy,
          "gi_dependents": 0,
          "income": 0,
          "min": 100000,
          "max": 1000000,
          "retire_age": assumptions.retire_age,
          "li_goal_id": selfLi ? selfLi.goal_id : '',
          "gi_goal_id": selfGi ? selfGi.goal_id : '',
          "auto_reset": "1",
          "select_opt": "1"
        }
        axioInstance.post(`goals/default`, selfLiParams).then(function (response) {

        })

        if (spouseDetails) {
          const goalParams = {
            "profile_id": sessionStorage.getItem('profile_id'),
            "spouse_prof_details_id": sessionStorage.getItem('spouse_prof_det_id'),
            "dob": ageCalculate(spouseDetails.dob),
            "inflation": assumptions.inflation,
            "income_grow": assumptions.income_grow,
            "api_type": "insert",
            "post_retire_inflation": assumptions.post_retire_inflation,
            "life_expentancy": assumptions.life_expentancy,
            "gi_dependents": 0,
            "income": 0,
            "min": 100000,
            "max": 1000000,
            "retire_age": assumptions.retire_age,
            "auto_reset": "1",
            "select_opt": "1",
            "goal_id": spouseLi ? spouseLi.goal_id : ''
          }
          axioInstance.post(`goals/add-spouse-life`, goalParams).then(function (response) {
            setButtonSpinner(false);
            window.location.reload(true);
          })
        } else {
          setButtonSpinner(false);
          window.location.reload(true);
        }



      })
      .catch(function (error) {
        console.log(error);
        SweetAlert.fire(
          'Expense',
          'Failed. Something went wrong. Retry again',
          'error'
        )
      });

  }

  const [expense, setExpense] = useState(null);

  const [Household, setHousehold] = useState(null);
  const [HouseholdFreq, setHouseholdFreq] = useState(null);
  const [HouseholdId, setHouseholdId] = useState(null);

  const [rentalExpense, setRentalExpense] = useState(null);
  const [rentalExpenseFreq, setRentalExpenseFreq] = useState(null);
  const [rentalExpenseId, setRentalExpenseId] = useState(null);

  const [UtilityBills, setUtilityBills] = useState(null);
  const [UtilityBillsFreq, setUtilityBillsFreq] = useState(null);
  const [UtilityBillsId, setUtilityBillsId] = useState(null);

  const [Lifestyle, setLifestyle] = useState(null);
  const [LifestyleFreq, setLifestyleFreq] = useState(null);
  const [LifestyleId, setLifestyleId] = useState(null);

  const [ChildrenEducation, setChildrenEducation] = useState(null);
  const [ChildrenEducationFreq, setChildrenEducationFreq] = useState(null);
  const [ChildrenEducationId, setChildrenEducationId] = useState(null);


  const [Vacation, setVacation] = useState(null);
  const [VacationFreq, setVacationFreq] = useState(null);
  const [VacationId, setVacationId] = useState(null);



  const [householdAmount, setHouseholdAmount] = useState();
  const [rentalExpenseAmount, setRentalExpenseAmount] = useState();
  const [utilityBillsAmount, setUtilityBillsAmount] = useState();
  const [lifestyleAmount, setLifestyleAmount] = useState();
  const [childrenEducationAmount, setChildrenEducationAmount] = useState();
  const [VacationAmount, setVacationAmount] = useState();

  const handleChange = (e) => {

    const { value } = e.target;

    if (e.target.name === 'Household') {
      setHouseholdAmount(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'rentalExpense') {
      setRentalExpenseAmount(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'UtilityBills') {
      setUtilityBillsAmount(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'Lifestyle') {
      setLifestyleAmount(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'ChildrenEducation') {
      setChildrenEducationAmount(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'Vacation') {
      setVacationAmount(formatAmount(value.replace(/,/g, "")));
    }

  };


  // eslint-disable-next-line
  const [spouseLi, setSpouseLi] = useState([]);
  // eslint-disable-next-line
  const [selfLi, setSelfLi] = useState([]);
  // eslint-disable-next-line
  const [selfGi, setSelfGi] = useState([]);
  // eslint-disable-next-line
  const [spouseDetails, setSpouseDetails] = useState([]);
  // eslint-disable-next-line
  const [selfDetails, setSelfDetails] = useState([]);
  // eslint-disable-next-line
  const [assumptions, setAssumptions] = useState([]);

  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null) {


      axioInstance.post(`profile/summary`, {profile_id:profile_id}).then(
        (response, data) => {
          if (response.data.status === 100) {



            let spouse_li_goal = response.data.risk_goals.find(product => product.plan_for === Decryption(sessionStorage.getItem('spouse_prof_det_id')));
            setSpouseLi(spouse_li_goal ? spouse_li_goal : '');

            let spouse_details = response.data.prof_det.find(product => product.relation === "spouse");
            setSpouseDetails(spouse_details ? spouse_details : false);

            let self_details = response.data.prof_det.find(product => product.relation === "self");
            setSelfDetails(self_details);

            let self_li_goal = response.data.risk_goals.find(product => (product.plan_for === Decryption(sessionStorage.getItem('profile_details_id')) && product.goal_type === "Life Insurance"));
            setSelfLi(self_li_goal ? self_li_goal : '');

            let self_gi_goal = response.data.risk_goals.find(product => (product.plan_for === Decryption(sessionStorage.getItem('profile_details_id')) && product.goal_type === "Health Insurance"));
            setSelfGi(self_gi_goal ? self_gi_goal : '');

            setAssumptions(response.data.assumption);

          }
        }, []);

      axioInstance.post(`get-income-expense`, { "profile_id": profile_id, "type": 2 }).then(
        (response, data) => {
          if (response.data.length > 0) {

            response.data.forEach(item => {

              switch (item.type_name_sf) {

                case 'Household':
                  setHouseholdId(item.income_id);
                  setHouseholdFreq(item.frequency);
                  setHousehold(item.amount);
                  break;

                case 'UtilityBills':
                  setUtilityBillsId(item.income_id);
                  setUtilityBillsFreq(item.frequency);
                  setUtilityBills(item.amount);
                  break;

                case 'ChildrenEducation':
                  setChildrenEducationId(item.income_id);
                  setChildrenEducationFreq(item.frequency);
                  setChildrenEducation(item.amount);
                  break;

                case 'Lifestyle':
                  setLifestyleId(item.income_id);
                  setLifestyleFreq(item.frequency);
                  setLifestyle(item.amount);
                  break;

                case 'Vacation':
                  setVacationId(item.income_id);
                  setVacationFreq(item.frequency);
                  setVacation(item.amount);
                  break;

                case 'rentalExpense':
                  setRentalExpenseId(item.income_id);
                  setRentalExpenseFreq(item.frequency);
                  setRentalExpense(item.amount);
                  break;

                default:
                  break;

              }

            });

            setExpense({ Household: formatAmount(Household, 0), HouseholdFreq: HouseholdFreq > 0 ? HouseholdFreq : 12, HouseholdId: HouseholdId, rentalExpense: formatAmount(rentalExpense, 0), rentalExpenseFreq: rentalExpenseFreq > 0 ? rentalExpenseFreq : 12, rentalExpenseId: rentalExpenseId, UtilityBills: formatAmount(UtilityBills, 0), UtilityBillsFreq: UtilityBillsFreq > 0 ? UtilityBillsFreq : 12, UtilityBillsId: UtilityBillsId, Lifestyle: formatAmount(Lifestyle, 0), LifestyleFreq: LifestyleFreq > 0 ? LifestyleFreq : 12, LifestyleId: LifestyleId, ChildrenEducation: formatAmount(ChildrenEducation), ChildrenEducationFreq: ChildrenEducationFreq > 0 ? ChildrenEducationFreq : 12, ChildrenEducationId: ChildrenEducationId, Vacation: formatAmount(Vacation), VacationFreq: VacationFreq > 0 ? VacationFreq : 12, VacationId: VacationId })
          }
        });
    }
  }, [Household, HouseholdFreq, HouseholdId, rentalExpense, rentalExpenseFreq, rentalExpenseId, UtilityBills, UtilityBillsFreq, UtilityBillsId, Lifestyle, LifestyleFreq, LifestyleId, ChildrenEducation, ChildrenEducationFreq, ChildrenEducationId, Vacation, VacationFreq, VacationId]);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(expense);
    // eslint-disable-next-line
  }, [expense]);

  return (
    <Fragment>

      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
            </div>
            <div className="font14 fw500 color182">
              Edit Expenses
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner topBanner_editExpenses pl25">
            <div className="font18 fw600 color182 mt50">Expenses</div>
            <div className="font14 fw500 color485">{sessionStorage.getItem('full_name')} family’s expenses</div>
          </div>

          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <input type="hidden" name="HouseholdId" {...register("HouseholdId")} />
              <input type="hidden" name="rentalExpenseId" {...register("rentalExpenseId")} />
              <input type="hidden" name="UtilityBillsId" {...register("UtilityBillsId")} />
              <input type="hidden" name="LifestyleId" {...register("LifestyleId")} />
              <input type="hidden" name="ChildrenEducationId" {...register("ChildrenEducationId")} />
              <input type="hidden" name="VacationId" {...register("VacationId")} />

              <div className="form-row">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw600">Household</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="Household" {...register("Household", { required: 'Household Expense is required' })} value={householdAmount} onChange={handleChange} />
                    <select className="custom-select" id="inputGroupSelect01" name="HouseholdFreq" {...register("HouseholdFreq")}>
                      <option value="12" selected>Monthly</option>
                      <option value="1">Yearly</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.Household && errors.Household.message}</small>
                </div>
              </div>

              <div className="form-row mt25">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw600">Rent</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="rentalExpense" {...register("rentalExpense", { required: 'Rental Expense is required' })} value={rentalExpenseAmount} onChange={handleChange} />
                    <select className="custom-select" id="inputGroupSelect01" name="rentalExpenseFreq" {...register("rentalExpenseFreq")}>
                      <option value="12" selected>Monthly</option>
                      <option value="1">Yearly</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.rentalExpense && errors.rentalExpense.message}</small>
                </div>
              </div>


              <div className="form-row mt25">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw600">Utility Bill</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="UtilityBills" {...register("UtilityBills", { required: 'Utility Bills Expense is required' })} value={utilityBillsAmount} onChange={handleChange} />
                    <select className="custom-select" id="inputGroupSelect01" name="UtilityBillsFreq" {...register("UtilityBillsFreq")}>
                      <option value="12" selected>Monthly</option>
                      <option value="1">Yearly</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.UtilityBills && errors.UtilityBills.message}</small>
                </div>
              </div>

              <div className="form-row mt25">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw600">Lifestyle</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="Lifestyle" {...register("Lifestyle", { required: 'Rental Income is required' })} value={lifestyleAmount} onChange={handleChange} />
                    <select className="custom-select" id="inputGroupSelect01" name="LifestyleFreq" {...register("LifestyleFreq")}>
                      <option value="12" selected>Monthly</option>
                      <option value="1">Yearly</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.Lifestyle && errors.Lifestyle.message}</small>
                </div>
              </div>

              <div className="form-row mt25">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw600">Kids Education</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="ChildrenEducation" {...register("ChildrenEducation", { required: 'Children Education Expense is required' })} value={childrenEducationAmount} onChange={handleChange} />
                    <select className="custom-select" id="inputGroupSelect01" name="ChildrenEducationFreq" {...register("ChildrenEducationFreq")}>
                      <option value="12" selected>Monthly</option>
                      <option value="1">Yearly</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.ChildrenEducation && errors.ChildrenEducation.message}</small>
                </div>
              </div>

              <div className="form-row mt25">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw600">Vacation</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="Vacation" {...register("Vacation", { required: 'Children Education Expense is required' })} value={VacationAmount} onChange={handleChange} />
                    <select className="custom-select" id="inputGroupSelect01" name="VacationFreq" {...register("VacationFreq")}>
                      <option value="12" selected>Monthly</option>
                      <option value="1">Yearly</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.Vacation && errors.Vacation.message}</small>
                </div>
              </div>


              <div className="saveBtn backsaveBtn">
                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Updating</> : "Save"} </button>
              </div>
            </form>
          </div>

        </div>
      </div>

    </Fragment>
  )
}