import React, { useEffect, useState } from 'react'
import formatAmount from 'indian-currency-formatter';

export default function HistoryRiskProfileSummary({ fpstatusData, riskLogs }) {


  // eslint-disable-next-line
  const [planHistory, setPlanHistory] = useState([]);
  const [planLatestStatus, setPlanLatestStatus] = useState();
  const [createdOn, setCreatedOn] = useState();
  // eslint-disable-next-line
  const [riskHistory, setRiskHistory] = useState([]);

  useEffect(() => {

    const rawData = JSON.parse(fpstatusData.FpPlanStatus_data);
    setPlanHistory(JSON.parse(rawData.history)); // Set empty array if no history
    // Set other values
    setPlanLatestStatus(rawData.latest_status || 'NA');
    setCreatedOn(rawData.CreatedOn || 'NA');

    console.log(riskLogs);
    setRiskHistory(riskLogs);


  }, [fpstatusData, riskLogs]);

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Risk & Plan Summary</span></div>
      <div className="font22 fw700 color0C4 pt30">Plan Summary</div>


      <div className="goalbox_title mt15">
        <div>
          <div className="font16 color263 fw600">Latest Status: <span className="font13 fw500 color626">{planLatestStatus}</span></div>
        </div>
        <div>
          <div className="achivetab fw500 font12 color353">Created On: <span className="color007 ml5">{createdOn}</span></div>
        </div>
      </div>

      {
        planLatestStatus ?
          planHistory.map((history, key) => {
            return (
              <>

                <div className="loanBox_outer mt25 p25">
                  <div className="loandtl">
                    <div className="font13 fw500 color6d7">Status</div>
                    <div className="font14 fw500 color182">{history.status}</div>
                  </div>
                  <div className="loandtl">
                    <div className="font13 fw500 color6d7">Status Date</div>
                    <div className="font14 fw500 color182">{new Date(history.fpHist_status_date).toLocaleDateString("en-GB")}</div>
                  </div>

                  <div className="loandtl">
                    <div className="font13 fw500 color6d7">Remark</div>
                    <div className="font14 fw500 color182">{history.fpHist_remark}</div>
                  </div>

                  {
                    history.status === "Plan Freezed" && (
                      <>
                        <div className="loandtl">
                          <div className="font13 fw500 color6d7">Monthly</div>
                          <div className="font14 fw500 color182">{formatAmount(history.fpstatus_amount_monthly_amount)}</div>
                        </div>
                        <div className="loandtl">
                          <div className="font13 fw500 color6d7">Yearly</div>
                          <div className="font14 fw500 color182">{formatAmount(history.fpstatus_amount_yearly_amount)}</div>
                        </div>

                        <div className="loandtl">
                          <div className="font13 fw500 color6d7">Increase By</div>
                          <div className="font14 fw500 color182">{history.fpstatus_amount_increase_by} %</div>
                        </div></>
                    )
                  }



                </div>





              </>
            )
          }) : ''
      }


      <div className="font22 fw700 color0C4 pt30">Risk Summary</div>



      {
        riskHistory.map((history, key) => {

          console.log(key);
          return (
            <>

              <div className="loanBox_outer mt25 p25">
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">Risk Description</div>
                  <div className="font14 fw500 color182">{history.riskProfileLogs_risk_description}</div>
                </div>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">Date</div>
                  <div className="font14 fw500 color182">{new Date(history.riskProfileLogs_created).toLocaleDateString("en-GB")}</div>
                </div>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">PDF</div>
                  <div className="font14 fw500 color182">{history.riskProfileLogs_ref_doc ? <><a href={"https://planapi.finnovate.in/uploads/riskpdf/" + history.riskProfileLogs_ref_doc} target='_blank' rel="noreferrer" className='color111'><i className="fas fa-file-pdf fa-2x"></i></a></> : ''}
                  </div>

                </div>
                {
                  key === 0 && (<><div className="loandtl">
                    <div className="inflowbox_title greendot font12 fw600 color6d7">Latest</div>
                  </div></>)
                }



              </div>

            </>
          )
        })
      }

    </>
  )
}
