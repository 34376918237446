import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
import SweetAlert from 'sweetalert2'
import ReCAPTCHA from 'react-google-recaptcha'
import { Encryption } from '../global';

export default function Ulogin({ setUforgotPass }) {
    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();
    const recaptchaRef = React.useRef();
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);

    const [isRememberMe, setIsRememberMe] = useState(false);

    const handleRecaptchaChange = (value) => {
        setIsRecaptchaVerified(true);
    }

    const handleRememberMeToggle = () => {
        setIsRememberMe(!isRememberMe);
    };

    useEffect(() => {
        const savedEmail = localStorage.getItem('remember_email');
        const savedPassword = localStorage.getItem('remember_password');
        const timestamp = localStorage.getItem('remember_timestamp');

        if (savedEmail && savedPassword && timestamp) {
            const currentTime = new Date().getTime();
            const twelveHours = 12 * 60 * 60 * 1000;

            if (currentTime - parseInt(timestamp) < twelveHours) {
                setIsRememberMe(true);
                autoLogin(savedEmail, savedPassword);
            } else {
                setIsRememberMe(false);
                clearRememberMe();
            }
        }
        // eslint-disable-next-line
    }, []);

    const clearRememberMe = () => {
        localStorage.removeItem('remember_email');
        localStorage.removeItem('remember_password');
        localStorage.removeItem('remember_timestamp');
    };

    const autoLogin = (email, password) => {
        const Postdata = {
            "email": email,
            "password": password,
        };

        axioInstance.post(`admin/login`, Postdata)
            .then((response) => {
                if (response.data.status === 101) {
                    SweetAlert.fire("Warning", response.data.msg, "warning");
                } else {
                    handleLoginSuccess(response.data);
                }
            })
            .catch(e => {
                SweetAlert.fire("Warning", e.response?.data?.message || "Auto-login failed", "warning");
                clearRememberMe();
            });
    };

    const handleLoginSuccess = (data) => {
        SweetAlert.fire({
            toast: true,
            icon: 'success',
            title: 'Signed in Successfully',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
        }).then(() => {
            localStorage.setItem('admin_id', data.admin_id);
            sessionStorage.setItem('admin_id', data.admin_id);
            sessionStorage.setItem('admin_name', data.admin_name);
            sessionStorage.setItem('admin_role', data.role);
            sessionStorage.setItem('admin_email', data.email_id);

            window.location.href = "/admin-dashboard";
        });
    };

    const onSubmit = (data) => {
        if (!isRecaptchaVerified) {
            SweetAlert.fire("Warning", "Please verify that you are not a robot.", "warning");
            return;
        }

        const Postdata = {
            "email": Encryption(data.emailAddress),
            "password": Encryption(data.password),
        };

        axioInstance.post(`admin/login`, Postdata)
            .then((response) => {
                if (response.data.status === 101) {
                    SweetAlert.fire("Warning", response.data.msg, "warning");
                } else {
                    if (isRememberMe) {
                        localStorage.setItem('remember_email', Encryption(data.emailAddress));
                        localStorage.setItem('remember_password', Encryption(data.password));
                        localStorage.setItem('remember_timestamp', new Date().getTime().toString());
                    }
                    handleLoginSuccess(response.data);
                }
            })
            .catch(e => {
                SweetAlert.fire("Warning", e.response?.data?.message || "Login failed", "warning");
            });
    };

    return (
        <>
           
            <div className="logincard">
                <div className="logincard_title pt30 pb30 pl35 pr35">
                    <div className="font18 color212 fw600">Login your account</div>
                    <div className="font13 fw500 color626">Join us and set your path towards Financial Freedom</div>
                </div>
                <div className="p35">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="font14 fw600 color212">Email<span className="colorfa8">*</span></div>
                        <div className="mt12 mobilefield">
                            <input type="text" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^\S+@\S+$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                        </div>
                        <span className="text-danger font11">{errors.emailAddress && errors.emailAddress.message}</span>

                        <div className="font14 fw600 color212 mt35">Password<span className="colorfa8">*</span></div>
                        <div className="mt12 mobilefield">
                            <input
                                type={togglePassword ? "text" : "password"}
                                name="password"
                                autoComplete="off"
                                {...register("password", { required: 'Password is required', minLength: 5, maxLength: 15 })}
                                placeholder="Password"
                            />
                            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                <span>{togglePassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</span>
                            </div>
                        </div>
                        <span className="text-danger font11">{errors.password && errors.password.message}</span>

                        <div className="recaptcha-container mt-3">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LfLyqopAAAAAMrrNZ0gu0_VZZtRh6uIhQc4TVCM"
                                onChange={handleRecaptchaChange}
                            />
                        </div>

                        <div className="mt20 ml20">
                            <input
                                type="checkbox"
                                id="rememberMe"
                                className="form-check-input"
                                checked={isRememberMe}
                                onChange={handleRememberMeToggle}
                            />
                            <label htmlFor="rememberMe" className="ml10">Remember Me</label>
                        </div>

                        <button type="submit" disabled={!isDirty && !isValid} className="btn btn-primary btn-lg btn-block font13 fw500 mt30">Continue</button>

                        <div className="signuptext mt30 font14 color263 fw500">
                            Unable to access account? <span className="color06d ml5 fw600 pointer" onClick={() => setUforgotPass(true)}>Forgot Password</span>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}