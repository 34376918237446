import React, { Fragment, useState, useEffect } from 'react'
import LoginFirst from '../components/login/LoginFirst';
import LoginVerifyOtp from '../components/login/LoginVerifyOtp';
import LoginWithPassword from '../components/login/LoginWithPassword';
import RegisterFirst from '../components/login/RegisterFirst';
import RegisterVerify from '../components/login/RegisterVerify';
import RegisterVerifyMobile from '../components/login/RegisterVerifyMobile';
import ResetFirst from '../components/login/ResetFirst';
import ResetOtp from '../components/login/ResetOtp';
import ResetSetPassword from '../components/login/ResetSetPassword';
import { Modal, ModalBody } from 'react-bootstrap';

export default function Login() {


    const [loginStep, setLoginStep] = useState(false);
    // eslint-disable-next-line
    const [loginType, setLoginType] = useState(false);

    const [loginPara, setLoginPara] = useState();

    // eslint-disable-next-line
    const [signupVerifyScreen, setSignupVerifyScreen] = useState(false);
    // eslint-disable-next-line
    const [signupVerifyPostData, setSignupVerifyPostData] = useState([]);
    // eslint-disable-next-line
    const [verifiedMobile, setVerifiedMobile] = useState(false);

    useEffect(() => {


        const search = window.location.search;
        const params = new URLSearchParams(search);
        const q = params.get('q');

        if (q === "2") {
            setLoginType('signup');
            setLoginStep(false);

            /*setSignupVerifyPostData({
                fullName: "Anoj Tambe",
                mobileNumber: "9930578095",
                emailAddress: "anojtambe@gmail.com",
                password: "123456789"
            });

            setSignupVerifyScreen('mobile');*/

        } else {
            setLoginStep(1);
        }


        document.body.classList.add('white_bg_fp_report');

        return function cleanup() {
            document.body.classList.remove('white_bg_fp_report');
        };




    }, []);


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [popupIcon, setPopupIcon] = useState('deleteicon');
    const [popupTitle, setPopupTitle] = useState();
    const [popupContent, setPopupContent] = useState();


    console.log('')
    return (
        <Fragment>

            <div className="innercontainer mb40">
                <div className="mainwrapper">

                    <Modal show={show} onHide={handleClose} className='modal model-sm' dialogClassName='modal-container' keyboard centered>
                        <ModalBody>
                            <div className="p20">
                                <div className="text-center">
                                    <span className={popupIcon} />
                                </div>
                                <div className="text-center mt10 color1e3 fw600 font18">
                                    {popupTitle}
                                </div>
                                <div className="text-center mt10 color485 fw500 font14">
                                    {popupContent}
                                </div>
                                <div className="smallbtn mt20">
                                    <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>Cancel</button>
                                    <button type="button" className={popupIcon !== "successIcon" ? "btn btn-danger" : "btn btn-success"} onClick={handleClose}>Continue</button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <div className="mt30 font20 fw600 color384 mob_pl20_pr20 d-none d-sm-block">Welcome to Finnovate,</div>
                    <div className="font13 color626 mob_pl20_pr20 d-none d-sm-block">Join us and set your path towards Financial Freedom</div>
                    <div className="row formWpr mt25">



                        <div className="col-lg-5 p0">



                            {(loginStep === 1 && loginType === false) &&
                                <LoginFirst
                                    setLoginType={setLoginType}
                                    setLoginStep={setLoginStep}
                                    setLoginPara={setLoginPara}
                                    setPopupIcon={setPopupIcon}
                                    setPopupTitle={setPopupTitle}
                                    setPopupContent={setPopupContent}
                                    verifiedMobile={verifiedMobile}
                                    setVerifiedMobile={setVerifiedMobile}
                                    setShow={setShow}
                                />
                            }

                            {
                                (loginStep === 2 && loginType === 'otp') &&
                                <LoginVerifyOtp
                                    setLoginStep={setLoginStep}
                                    setLoginType={setLoginType}
                                    loginPara={loginPara}
                                    setPopupIcon={setPopupIcon}
                                    setPopupTitle={setPopupTitle}
                                    setPopupContent={setPopupContent}
                                    verifiedMobile={verifiedMobile}
                                    setVerifiedMobile={setVerifiedMobile}
                                    setShow={setShow}
                                />
                            }
                            {
                                (loginStep === 2 && loginType === 'password') &&
                                <LoginWithPassword
                                    setLoginStep={setLoginStep}
                                    setLoginType={setLoginType}
                                    loginPara={loginPara}
                                    setPopupIcon={setPopupIcon}
                                    setPopupTitle={setPopupTitle}
                                    setPopupContent={setPopupContent}
                                    setShow={setShow}
                                />
                            }
                            {
                                (loginStep === 2 && loginType === 'forgotpassword') &&
                                <ResetFirst
                                    setLoginStep={setLoginStep}
                                    setLoginType={setLoginType}
                                    setLoginPara={setLoginPara}
                                    loginPara={loginPara}
                                    setPopupIcon={setPopupIcon}
                                    setPopupTitle={setPopupTitle}
                                    setPopupContent={setPopupContent}
                                    setShow={setShow}
                                />
                            }
                            {
                                (loginStep === 2 && loginType === 'forgotpasswordOtp') &&
                                <ResetOtp
                                    setLoginStep={setLoginStep}
                                    setLoginType={setLoginType}
                                    loginPara={loginPara}
                                    setPopupIcon={setPopupIcon}
                                    setPopupTitle={setPopupTitle}
                                    setPopupContent={setPopupContent}
                                    setShow={setShow}
                                />
                            }

                            {
                                (loginStep === 2 && loginType === 'forgotpasswordSetPassword') &&
                                <ResetSetPassword
                                    setLoginStep={setLoginStep}
                                    setLoginType={setLoginType}
                                    loginPara={loginPara}
                                    setPopupIcon={setPopupIcon}
                                    setPopupTitle={setPopupTitle}
                                    setPopupContent={setPopupContent}
                                    setShow={setShow}
                                />
                            }

                            {
                                (loginType === 'signup' && signupVerifyScreen === false) &&
                                <RegisterFirst
                                    setLoginStep={setLoginStep}
                                    setLoginType={setLoginType}
                                    loginPara={loginPara}
                                    signupVerifyScreen={signupVerifyScreen}
                                    setSignupVerifyScreen={setSignupVerifyScreen}
                                    signupVerifyPostData={signupVerifyPostData}
                                    setSignupVerifyPostData={setSignupVerifyPostData}
                                    setPopupIcon={setPopupIcon}
                                    setPopupTitle={setPopupTitle}
                                    setPopupContent={setPopupContent}
                                    setShow={setShow}
                                />
                            }

                            {
                                (loginType === 'signup' && signupVerifyScreen === true) &&
                                <RegisterVerify
                                    setLoginStep={setLoginStep}
                                    setLoginType={setLoginType}
                                    loginPara={loginPara}
                                    signupVerifyScreen={signupVerifyScreen}
                                    setSignupVerifyScreen={setSignupVerifyScreen}
                                    signupVerifyPostData={signupVerifyPostData}
                                    setSignupVerifyPostData={setSignupVerifyPostData}
                                    setPopupIcon={setPopupIcon}
                                    setPopupTitle={setPopupTitle}
                                    setPopupContent={setPopupContent}
                                    setShow={setShow}
                                />
                            }

                            {
                                (loginType === 'signup' && signupVerifyScreen === "mobile") &&
                                <RegisterVerifyMobile
                                    setLoginStep={setLoginStep}
                                    setLoginType={setLoginType}
                                    loginPara={loginPara}
                                    signupVerifyScreen={signupVerifyScreen}
                                    setSignupVerifyScreen={setSignupVerifyScreen}
                                    signupVerifyPostData={signupVerifyPostData}
                                    setSignupVerifyPostData={setSignupVerifyPostData}
                                    setPopupIcon={setPopupIcon}
                                    setPopupTitle={setPopupTitle}
                                    setPopupContent={setPopupContent}
                                    setShow={setShow}
                                />
                            }



                        </div>
                        <div className="col-lg-7 qouteouter d-none d-md-block">
                            <video
                                autoPlay
                                muted
                                loop
                                style={{ height: "100%", width: "100%", objectFit: "cover" }} //object-fit:cover
                            >
                                <source src={"https://planapi.finnovate.in/login-2.mp4"} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                </div>
            </div>


        </Fragment>
    )
}
