import React, { useState, useEffect } from 'react'
import axioInstance from '../components/axiosInstance'

import HistoryWelcomeNote from '../components/historyfpreports/HistoryWelcomeNote'
import Disclaimer from '../components/fpreports/Disclaimer'
import HistoryPersonalInformation from '../components/historyfpreports/HistoryPersonalInformation'
import HistoryCashflowAnalysis from '../components/historyfpreports/HistoryCashflowAnalysis'
import HistoryAssetswhatyouown from '../components/historyfpreports/HistoryAssetswhatyouown'
import HistoryNetworthAnalysis from '../components/historyfpreports/HistoryNetworthAnalysis'
import HistoryEmergencyFund from '../components/historyfpreports/HistoryEmergencyFund'
import HistoryLoanManagement from '../components/historyfpreports/HistoryLoanManagement'
import HistoryLifeInsuranceAnalysis from '../components/historyfpreports/HistoryLifeInsuranceAnalysis'
import HistoryHealthAnalysis from '../components/historyfpreports/HistoryHealthAnalysis'
import HistoryGoalSummary from '../components/historyfpreports/HistoryGoalSummary'

import DashboardHeader from '../components/layout/DashboardHeader'
import RiskProfileSummary from '../components/fpreports/RiskProfileSummary'
import HistoryRiskProfileSummary from '../components/historyfpreports/HistoryRiskProfileSummary'

import { Encryption } from '../components/global'

export default function HistoryFpReport() {

    const [active, setActive] = useState(1);
    const [fullname, setFullname] = useState();
    const [familyData, setFamilyData] = useState([]);
    const [cashflowData, setCashflowData] = useState([]);
    const [liabilityData, setLiabilityData] = useState([]);
    const [investmentData, setInvestmentData] = useState([]);
    const [networth, setNetworth] = useState([]);

    const [assumptionData, setAssumptionData] = useState([]);
    const [lifeGoalsData, setLifeGoalsData] = useState([]);
    const [riskInvData, setRiskInvData] = useState([]);

    const [goalsData, setGoalsData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);

    const [fpstatusData, setFpstatusData] = useState([]);

    const [riskProfileDesc, setRiskProfileDesc] = useState();

    const [riskQnaData, setRiskQnaData] = useState([]);

    const [outflowgraphData, setOutflowgraphData] = useState([]);

    const [statusType, setStatusType] = useState();

    const [riskEquity, setRiskEquity] = useState();
    const [riskDebt, setRiskDebt] = useState();
    const [asset, setAsset] = useState();

    const [riskLogs, setRiskLogs] = useState([]);

    useEffect(() => {
        document.body.classList.add('white_bg_fp_report');

        return function cleanup() {
            document.body.classList.remove('white_bg_fp_report');
        };

    }, []);

    useEffect(() => {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const profile_id = params.get('id');
        const doc_id = params.get("doc_id");

        axioInstance.post(`profile/get-freeze-plan-data`, { 'profile_id': Encryption(profile_id), 'doc_id': doc_id }).then(
            (response, data) => {
                setStatusType(response.data.status_type ? response.data.status_type : '');
                setFullname(response.data.full_name);
                setFamilyData(response.data.familyData);
                setCashflowData(response.data.cashflowData);
                setLiabilityData(response.data.liabilityData);
                setInvestmentData(response.data.assets_owns);
                setNetworth(response.data.networth);
                setAssumptionData(response.data.assumptions);
                setLifeGoalsData(response.data.riskGoalsData);
                setRiskInvData(response.data.risk_inv);
                setGoalsData(response.data.goalsData);
                setSummaryData(response.data.summaryData[0])
                setRiskProfileDesc(response.data.riskprofileData[0].riskProfileLogs_risk_description);

                if (response?.data?.riskprofileData?.length > 0) {
                    setRiskLogs(response.data.riskprofileData);
                }

                

                if (response?.data?.FpPlanStatus?.length > 0) {
                    setFpstatusData(response.data.FpPlanStatus[0]);
                }

                if (response.data.riskprofileData[0].riskProfileLogs_risk_id !== '') {
                    setRiskEquity(response.data.riskprofileData[0].riskProfileLogs_max_risk);
                    setRiskDebt(100 - response.data.riskprofileData[0].riskProfileLogs_max_risk);
                }

                setAsset(response.data.summaryData[0].profGoalsSummary_goal_asset_alloc ? response.data.summaryData[0].profGoalsSummary_goal_asset_alloc : 0);

                setOutflowgraphData(response.data.cashflow ? response.data.cashflow.cashflow : []);

                const quest = response.data.riskprofileData[0].riskProfileLogs_q_n_a ? JSON.parse(response.data.riskprofileData[0].riskProfileLogs_q_n_a) : '';
                setRiskQnaData(quest ? quest.question : []);
            });


    }, []);

    return (
        <>
            <DashboardHeader />

            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    <div className="planSection_wpr row">
                        <div className="col-3 leftNav pt55 font14 fw500 color7B8">
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <h1 className='color0C4' style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left', marginTop: '-30px' }}>
                                    Plan {statusType ? statusType : 'Freezed'} History
                                </h1>
                                <a className="nav-link active" id="Welcomenote-tab" data-toggle="pill" href="#Welcomenote" role="tab" aria-controls="Welcomenote" aria-selected="true" onClick={() => setActive(1)}> <span className="welcomeNoteIcon mr10" /> Welcome note</a>

                                <a className="nav-link" id="Disclaimer-tab" data-toggle="pill" href="#Disclaimer" role="tab" aria-controls="Disclaimer" aria-selected="false" onClick={() => setActive(2)}><span className="disclaimerIcon mr10" /> Disclaimer</a>

                                <a className="nav-link" id="PersonalInformation-tab" data-toggle="pill" href="#PersonalInformation" role="tab" aria-controls="PersonalInformation" aria-selected="false" onClick={() => setActive(3)}><span className="personalInformationIcon mr10" /> Personal Information</a>

                                <a className="nav-link" id="CashflowAnalysis-tab" data-toggle="pill" href="#CashflowAnalysis" role="tab" aria-controls="CashflowAnalysis" aria-selected="false" onClick={() => setActive(4)}><span className="cashflowAnalysisIcon mr10" /> Cashflow Analysis</a>

                                <a className="nav-link" id="Whatyouown-tab" data-toggle="pill" href="#Whatyouown" role="tab" aria-controls="Whatyouown" aria-selected="false" onClick={() => setActive(5)}><span className="assetsIcon mr10" /> Assets - What you own</a>

                                <a className="nav-link" id="NetworthAnalysis-tab" data-toggle="pill" href="#NetworthAnalysis" role="tab" aria-controls="NetworthAnalysis" aria-selected="false" onClick={() => setActive(6)}><span className="networthAnalysisIcon mr10" /> Networth Analysis</a>

                                <a className="nav-link" id="EmergencyFund-tab" data-toggle="pill" href="#EmergencyFund" role="tab" aria-controls="EmergencyFund" aria-selected="false" onClick={() => setActive(7)}><span className="emergencyFundIcon mr10" /> Emergency Fund</a>

                                <a className="nav-link" id="LoanManagement-tab" data-toggle="pill" href="#LoanManagement" role="tab" aria-controls="LoanManagement" aria-selected="false" onClick={() => setActive(8)}><span className="loanManagementIcon mr10" /> Loan Management</a>

                                <a className="nav-link" id="LifeInsuranceAnalysis-tab" data-toggle="pill" href="#LifeInsuranceAnalysis" role="tab" aria-controls="LifeInsuranceAnalysis" aria-selected="false" onClick={() => setActive(9)}><span className="lifeAnalysisIcon mr10" /> Life Insurance Analysis</a>

                                <a className="nav-link" id="HealthAnalysis-tab" data-toggle="pill" href="#HealthAnalysis" role="tab" aria-controls="HealthAnalysis" aria-selected="false" onClick={() => setActive(10)}><span className="healthAnalysisIcon mr10" /> Health Analysis</a>

                                <a className="nav-link" id="GoalSummary-tab" data-toggle="pill" href="#GoalSummary" role="tab" aria-controls="GoalSummary" aria-selected="false" onClick={() => setActive(11)}><span className="goalSummaryIcon mr10" /> Goal Summary</a>

                                <a className="nav-link" id="RiskProfileSummary-tab" data-toggle="pill" href="#RiskProfileSummary" role="tab" aria-controls="RiskProfileSummary" aria-selected="false" onClick={() => setActive(12)}><span className="goalSummaryIcon mr10" /> Plan History</a>
                            </div>

                        </div>
                        <div className="col-9 righttabContent pl90 pt25">
                            <div class="mt30"><a className="btn bluebtn wizard-btn backtowizardBtn font14 fw600" href="/fp-report">Back to Plan</a> </div>

                            <div className="tab-content" id="v-pills-tabContent">
                                {
                                    active === 1 && (<><div className="tab-pane fade show active" id="Welcomenote" role="tabpanel" aria-labelledby="Welcomenote-tab">
                                        <HistoryWelcomeNote fullname={fullname} />
                                    </div></>)
                                }

                                {
                                    active === 2 && (<><div className="tab-pane fade" id="Disclaimer" role="tabpanel" aria-labelledby="Disclaimer-tab">
                                        <Disclaimer />
                                    </div></>)
                                }


                                {
                                    active === 3 && (<><div className="tab-pane fade" id="PersonalInformation" role="tabpanel" aria-labelledby="PersonalInformation-tab">
                                        <HistoryPersonalInformation familyData={familyData} />
                                    </div></>)
                                }


                                {
                                    active === 4 && (<><div className="tab-pane fade" id="CashflowAnalysis" role="tabpanel" aria-labelledby="CashflowAnalysis-tab">
                                        <HistoryCashflowAnalysis cashflowData={cashflowData} liabilityData={liabilityData} networthData={networth} />
                                    </div></>)
                                }


                                {
                                    active === 5 && (<><div className="tab-pane fade" id="Whatyouown" role="tabpanel" aria-labelledby="Whatyouown-tab">
                                        <HistoryAssetswhatyouown investmentData={investmentData} riskEquity={riskEquity} riskDebt={riskDebt} asset={asset} />
                                    </div></>)
                                }


                                {
                                    active === 6 && (<><div className="tab-pane fade" id="NetworthAnalysis" role="tabpanel" aria-labelledby="NetworthAnalysis-tab">
                                        <HistoryNetworthAnalysis networthData={networth} riskProfileDesc={riskProfileDesc} riskQnaData={riskQnaData} />
                                    </div></>)
                                }


                                {
                                    active === 7 && (<><div className="tab-pane fade" id="EmergencyFund" role="tabpanel" aria-labelledby="EmergencyFund-tab">
                                        <HistoryEmergencyFund networthData={networth} />
                                    </div></>)
                                }


                                {
                                    active === 8 && (<><div className="tab-pane fade" id="LoanManagement" role="tabpanel" aria-labelledby="LoanManagement-tab">
                                        <HistoryLoanManagement liabilityData={liabilityData} networthData={networth} />
                                    </div></>)
                                }


                                {
                                    active === 9 && (<><div className="tab-pane fade" id="LifeInsuranceAnalysis" role="tabpanel" aria-labelledby="LifeInsuranceAnalysis-tab">
                                        <HistoryLifeInsuranceAnalysis lifeGoalsData={lifeGoalsData} assumptionData={assumptionData} riskInvData={riskInvData} />
                                    </div></>)
                                }


                                {
                                    active === 10 && (<><div className="tab-pane fade" id="HealthAnalysis" role="tabpanel" aria-labelledby="HealthAnalysis-tab">
                                        <HistoryHealthAnalysis lifeGoalsData={lifeGoalsData} riskInvData={riskInvData} />
                                    </div></>)
                                }


                                {
                                    active === 11 && (<><div className="tab-pane fade" id="GoalSummary" role="tabpanel" aria-labelledby="GoalSummary-tab">
                                        <HistoryGoalSummary goalsData={goalsData} assumptionData={assumptionData} summaryData={summaryData} riskProfileDesc={riskProfileDesc} networthData={networth} outflowgraphData={outflowgraphData} />
                                    </div></>)
                                }

                                {
                                    active === 12 && (<><div className="tab-pane fade" id="RiskProfileSummary" role="tabpanel" aria-labelledby="RiskProfileSummary-tab">
                                        {fpstatusData ? <HistoryRiskProfileSummary fpstatusData={fpstatusData} riskLogs={riskLogs}/> : <RiskProfileSummary fpstatusData={fpstatusData} />}
                                    </div></>)
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
